import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from '@app/auth-msal/configs';
import { NoCacheInterceptor } from '@app/auth-msal/interceptors/no-cache.interceptor';
import {
  ApiPrefixInterceptor,
  ConcurrentIdenticalRequestInterceptor,
  HttpCacheInterceptor,
} from '@avenir-client-web/http';
import { APP_CONFIG } from '@avenir-client-web/models';
import { ProgressBarInterceptor } from '@avenir-client-web/progress-bar';
import { ProgressSpinnerInterceptor } from '@avenir-client-web/progress-spinner';
import { SafePipe } from '@avenir-client-web/safe-pipe';
import { SanitizerPipe } from '@avenir-client-web/sanitizer';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { ErrorHandlerInterceptor } from '@core/http/error-handler.interceptor';
import { PayloadHeaderInterceptor } from '@core/http/payload-header.interceptor';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProgressBarInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProgressSpinnerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCacheInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConcurrentIdenticalRequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [APP_CONFIG],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [APP_CONFIG],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [APP_CONFIG],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PayloadHeaderInterceptor,
      multi: true,
    },
    MessageService,
    MsalBroadcastService,
    MsalService,
    MsalGuard,
    DialogService,
    SanitizerPipe,
    SafePipe,
  ],
  bootstrap: [MsalRedirectComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        `${parentModule} has already been loaded. Import Core module in the AppModule only.`
      );
    }
  }
}
