import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { translate } from '../utils/translate.function';

@Injectable()
@Pipe({
  name: 'localizer',
})
export class LocalizerPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    return translate(value);
  }
}
