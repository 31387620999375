import { Component, Input } from '@angular/core';
import { GenericInfo } from '@app/generic-page/models/generic-page.model';
import { CredentialsService } from '@core/authentication/credentials.service';
import { AppRootRoute } from '@core/enums/app-route.enum';
import { PermissionService } from '@core/services/permission.service';
import { BaseComponent } from '@shared/base-component/base.component';

@Component({
  selector: 'app-forbidden-page',
  templateUrl: './forbidden-page.component.html',
  styleUrls: ['./forbidden-page.component.scss'],
})
export class ForbiddenPageComponent extends BaseComponent {
  @Input() forbiddenPageInfo: GenericInfo = {
    title: $localize`genericPage.title`,
    description: $localize`genericPage.description`,
    descriptionTitle: $localize`genericPage.descriptionTitle`,
    redirectionBtns: [
      {
        title: $localize`genericPage.dashboardBtn`,
        route: AppRootRoute.HOME,
      },
      {
        title: $localize`genericPage.wikiBtn`,
        route: AppRootRoute.WIKIS,
      },
      {
        title: $localize`genericPage.processBtn`,
        route: AppRootRoute.HOME,
        fragment: 'category',
      },
      {
        title: $localize`genericPage.newsBtn`,
        route: AppRootRoute.NEWS,
      },
    ],
  };

  readonly defaultPreviewImage = 'assets/images/forbidden-background.png';

  readonly defaultGroupImage = 'forbidden';

  constructor(
    override readonly credentialsService: CredentialsService,
    override readonly permissionService: PermissionService
  ) {
    super(credentialsService, permissionService);
  }
}
