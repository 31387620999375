import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizer',
})
export class SanitizerPipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  transform(value: string, context?: SecurityContext): SafeValue {
    if (!value) return '';

    if (context) return this.sanitizer.sanitize(context, value);

    return this.sanitizer.sanitize(SecurityContext.HTML, value);
  }
}
