import { Injectable } from '@angular/core';
import { Event as RouterEvent, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter, merge, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShellService {
  constructor(private readonly router: Router) {}

  scrollToTopSubject$ = new Subject<void>();

  resizeSubject$ = new Subject<number>();

  needHelpVisibility$ = new BehaviorSubject(true);

  scrollToTop$: Observable<RouterEvent | void> = merge(
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)),
    this.scrollToTopSubject$
  );
}
