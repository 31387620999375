<div class="field infinity-multi-select-container">
  <label *ngIf="label" class="block" [ngClass]="{ required: hasAsterisk }">{{
    label
  }}</label>
  <p-multiSelect
    (onChange)="onValueChange($event.value)"
    (onFilter)="filterChanges$.next($event.filter)"
    (onPanelHide)="onPanelHide()"
    [(ngModel)]="value"
    [emptyFilterMessage]="emptyFilterMessage"
    [filter]="true"
    [itemSize]="itemSize"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [options]="(options$ | async) || options"
    [placeholder]="placeholder"
    [showHeader]="true"
    [showToggleAll]="false"
    [styleClass]="styleClass"
    [virtualScroll]="true"
    [disabled]="disabled"
    display="chip"
    dropdownIcon="fa-solid fa-angle-down"
    emptyMessage="default.enterToSearch"
    filterPlaceHolder="default.search"
    i18n-emptyMessage
    i18n-filterPlaceHolder
    panelStyleClass="infinity-multi-select"
    resetFilterOnHide="true"
    [class.no-ellipsis]="noEllipsis"
    [dataKey]="dataKey"
    [class.readonly]="readonly"
  >
    <ng-template pTemplate="selectedItems">
      <span *ngIf="!value || value?.length === 0">
        {{ placeholder }}
      </span>
      <div class="item" *ngFor="let item of value || []">
        {{ item.name }}
        <span
          *ngIf="!readonly"
          class="pi pi-times-circle p-multiselect-token-icon"
          (click)="removeItem(item[dataKey])"
        ></span>
      </div>
    </ng-template>
  </p-multiSelect>
  <ng-content></ng-content>
</div>
