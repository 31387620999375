import { InjectionToken } from '@angular/core';
import { LogLevel } from '@avenir-client-web/models';

export const APP_CONFIG = new InjectionToken<BaseAppConfig>('app.config');

export class BaseAppConfig {
  serverUrl: string;

  defaultLanguage: string;

  supportedLanguages: string[];

  logLevel: LogLevel;

  recaptchaSiteKey?: string;

  copyright?: string;

  appSettings?: {
    logoPosition: string;
    defaultEmail: string;
    learning: { url: string };
    dashboard: {
      workingHour: { showMessage: boolean };
      holiday: { showMessage: boolean };
    };
  };

  featureToggle?: {
    skills: boolean;
    description: boolean;
    tickets: boolean;
    profile: { skills: boolean; description: boolean };
    search: {
      process: boolean;
      news: boolean;
      wiki: boolean;
      people: boolean;
      learning: boolean;
      all: boolean;
    };
  };
}
