import { FileExtension } from '../enums/file-extension.enum';

export interface FileItem {
  id: number | string;
  name: string;
  extension: FileExtension;
  size: number;
  createdDate: string;
  uploadedBy: string;
}
