import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  RelatedContent,
  RelatedContentType,
} from '@app/content-category/models/content-category.models';
import { NeedHelpComponent } from '@app/shell/components/need-help/need-help.component';
import { HttpService } from '@avenir-client-web/http';
import {
  ListResponse,
  PagingResponse,
  RequestOption,
  SingleResponse,
} from '@avenir-client-web/models';
import { MetadataItem } from '@core/models/metadata-item.model';
import { User } from '@core/models/profile.model';
import {
  chatApi,
  getTicketManagersApi,
  metadataApi,
  ticketApi,
  ticketMetadataApi,
} from '@shared/constants/api.constants';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { filter, map, Observable, pluck, takeUntil, tap } from 'rxjs';
import { TicketCreationComponent } from '../components/ticket-creation/ticket-creation.component';
import {
  ActionTicket,
  ActionTicketDetail,
  ActionTicketFormValue,
  InfoTicketFormValue,
  InfoTicketMetadata,
  TicketChat,
} from '../models/ticket.models';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  constructor(
    private readonly dialogService: DialogService,
    private readonly httpService: HttpService,
    private readonly router: Router
  ) {}

  openNeedHelpDialog(): void {
    const needHelpDialog = this.dialogService.open(NeedHelpComponent, {
      closeOnEscape: false,
      showHeader: false,
      styleClass: 'content-layout-dialog',
    });

    this.closeDialogOnNavigationChange(needHelpDialog);
  }

  openTicketCreationDialog(): void {
    const ticketCreationDialog = this.dialogService.open(
      TicketCreationComponent,
      {
        closable: false,
        closeOnEscape: false,
        showHeader: false,
        styleClass: 'content-layout-dialog',
      }
    );

    this.closeDialogOnNavigationChange(ticketCreationDialog);
  }

  getMetadata(): Observable<MetadataItem[]> {
    return this.httpService
      .get<ListResponse<MetadataItem>>(ticketMetadataApi)
      .pipe(pluck('data'));
  }

  createActionTicket(ticket: ActionTicketFormValue): Observable<void> {
    return this.httpService.post(ticketApi, ticket);
  }

  getTickets(options: RequestOption): Observable<PagingResponse<ActionTicket>> {
    const url = this.httpService.buildRequestUrl(ticketApi, options);

    return this.httpService.get<PagingResponse<ActionTicket>>(url);
  }

  getMyTickets(
    options: RequestOption
  ): Observable<PagingResponse<ActionTicket>> {
    const url = this.httpService.buildRequestUrl(`${ticketApi}/me`, options);

    return this.httpService.get<PagingResponse<ActionTicket>>(url);
  }

  getTicketDetail(id: number): Observable<ActionTicketDetail> {
    return this.httpService
      .get<SingleResponse<ActionTicketDetail>>(`${ticketApi}/detail/${id}`)
      .pipe(pluck('data'));
  }

  getTicketChat(
    ticketId: number,
    messageId?: number
  ): Observable<TicketChat[]> {
    let requestUrl = `${chatApi}?ticketId=${ticketId}`;

    if (messageId) requestUrl += `&messageId=${messageId}`;

    return this.httpService
      .get<ListResponse<TicketChat>>(`${requestUrl}`)
      .pipe(map(res => res.data));
  }

  sendTicketChat(chatRequest: TicketChat): Observable<TicketChat> {
    return this.httpService
      .post<SingleResponse<TicketChat>>(chatApi, chatRequest)
      .pipe(map(res => res.data));
  }

  updateTicket(ticket: ActionTicketDetail): Observable<void> {
    return this.httpService.post<void>(`${ticketApi}/update`, ticket);
  }

  updateMailState(id: number): Observable<boolean> {
    return this.httpService
      .post<SingleResponse<boolean>>(
        `${ticketApi}/${id}/update-email-state`,
        null
      )
      .pipe(pluck('data'));
  }

  getRelatedContent(
    requestOption: RequestOption
  ): Observable<ListResponse<RelatedContent>> {
    const url = this.httpService.buildRequestUrl(
      `${metadataApi}/related-contents`,
      requestOption
    );

    return this.httpService.get<ListResponse<RelatedContent>>(url);
  }

  getInfoTicketMetadata(): Observable<InfoTicketMetadata> {
    return this.httpService
      .get<SingleResponse<InfoTicketMetadata>>(
        `${ticketApi}/information/${metadataApi}`
      )
      .pipe(pluck('data'));
  }

  createInfoTicket(ticket: InfoTicketFormValue): Observable<void> {
    return this.httpService.post(`${ticketApi}/information`, ticket);
  }

  getTicketManagers(apiParams: {
    categoryId: number;
    type: RelatedContentType;
    relatedContentId: number;
  }): Observable<User[]> {
    let requestUrl = getTicketManagersApi;

    Object.keys(apiParams).forEach(
      param =>
        (requestUrl = requestUrl.replace(`:${param}`, `${apiParams[param]}`))
    );

    return this.httpService
      .get<ListResponse<User>>(`${requestUrl}`)
      .pipe(map(res => res.data));
  }

  private closeDialogOnNavigationChange(dialog: DynamicDialogRef): void {
    const currentUrl = this.router.url;

    this.router.events
      .pipe(
        filter(
          event => event instanceof NavigationEnd && event.url !== currentUrl
        ),
        tap(() => dialog.close()),
        takeUntil(dialog.onClose)
      )
      .subscribe();
  }
}
