<form [formGroup]="formGroup" class="info-ticket-form">
  <app-multi-select
    [formControlName]="formKey.ROLES"
    i18n-placeholder
    placeholder="ticket.roles.placeholder"
    i18n-label
    label="ticket.roles"
    [options]="(metadata$ | async)?.roles"
    optionLabel="name"
  >
  </app-multi-select>

  <app-infinity-multi-select
    [formControlName]="formKey.RECIPIENTS"
    [hasAsterisk]="true"
    i18n-label
    label="ticket.recipients"
    optionLabel="name"
    i18n-placeholder
    placeholder="ticket.recipients.placeholder"
    dataKey="aadId"
    [infinityConfig]="recipientsInfinityConfig"
    class="recipients"
  >
    <small
      *ngIf="recipientsControl?.hasError(formErrorKey.REQUIRED)"
      class="p-error block"
      i18n
      >default.error.required
    </small>
  </app-infinity-multi-select>

  <app-input
    class="ticket-title"
    [formControlName]="formKey.TITLE"
    i18n-label
    label="ticket.title"
    i18n-placeholder
    placeholder="ticket.title.placeholder"
    [maxLength]="titleMaxLength"
    [hasAsterisk]="true"
  >
    <small
      *ngIf="titleControl?.hasError(formErrorKey.REQUIRED)"
      class="p-error block"
      i18n
      >default.error.required
    </small>
  </app-input>

  <app-text-area
    [formControlName]="formKey.CONTENT"
    i18n-placeholder
    placeholder="infoTicket.content.placeholder"
    i18n-label
    label="ticket.content"
    [rows]="5"
    [maxLength]="contentMaxLength"
    [hasAsterisk]="true"
  >
    <small
      *ngIf="contentControl?.hasError(formErrorKey.REQUIRED)"
      class="p-error block"
      i18n
      >default.error.required
    </small>
  </app-text-area>

  <app-input
    [formControlName]="formKey.LINK"
    i18n-label
    label="ticket.link"
    i18n-placeholder
    placeholder="ticket.link.placeholder"
  >
    <small
      *ngIf="linkControl?.hasError(formErrorKey.REQUIRED)"
      class="p-error block"
      i18n
      >default.error.required
    </small>

    <small
      *ngIf="linkControl?.hasError(formErrorKey.MAX_LENGTH)"
      class="p-error block"
      i18n
      >linkForm.error.linkMaxLength
    </small>
  </app-input>
</form>
