import { Injectable } from '@angular/core';
import { notificationType } from '@app/notification/constants/notification.constants';
import { HttpService } from '@avenir-client-web/http';
import { LoggerService } from '@avenir-client-web/logger';
import {
  PagingResponse,
  RequestOption,
  SingleResponse,
} from '@avenir-client-web/models';
import { ProfileService } from '@core/services/profile.service';
import { notificationApi } from '@shared/constants/api.constants';
import { map, Observable, pluck, shareReplay } from 'rxjs';
import {
  ActionTicketNotification,
  BaseNotification,
  NotificationResponse,
} from '../models/notification.models';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private readonly httpService: HttpService,
    private readonly logger: LoggerService,
    private readonly profileService: ProfileService
  ) {}

  getNotifications(
    requestOption: RequestOption
  ): Observable<PagingResponse<BaseNotification>> {
    const url = this.httpService.buildRequestUrl(
      notificationApi,
      requestOption
    );

    return this.httpService.get<PagingResponse<NotificationResponse>>(url).pipe(
      map(response => {
        response.data.forEach(
          (val, index, arr) =>
            ((arr as BaseNotification[])[index] = this.mapNotification(val))
        );

        return response as PagingResponse<BaseNotification>;
      })
    );
  }

  markAllAsRead(): Observable<void> {
    return this.httpService.post<void>(
      `${notificationApi}/mark-all-as-read`,
      null
    );
  }

  markAsRead(id: number): Observable<void> {
    return this.httpService.post<void>(
      `${notificationApi}/${id}/mark-as-read`,
      null
    );
  }

  markAsUnread(id: number): Observable<void> {
    return this.httpService.post<void>(
      `${notificationApi}/${id}/mark-as-unread`,
      null
    );
  }

  checkUnread(): Observable<boolean> {
    return this.httpService
      .get<SingleResponse<boolean>>(`${notificationApi}/has-unread`)
      .pipe(pluck('data'), shareReplay());
  }

  private mapNotification<T extends BaseNotification>(
    notificationRes: NotificationResponse
  ): T {
    let data: Record<string, unknown>;
    let notification: T = { ...notificationRes } as unknown as T;

    try {
      data = JSON.parse(notificationRes.data);

      data && (notification = { ...notification, ...data });

      if (notification?.type === notificationType.ticket.action.createBySelf) {
        delete (notification as ActionTicketNotification).userAadId;
        delete (notification as ActionTicketNotification).userName;
      }

      (notification as ActionTicketNotification)?.userAadId &&
        ((notification as ActionTicketNotification).avatarUrl$ =
          this.profileService.getAvatarUrl(data.userAadId as string));
    } catch (error) {
      this.logger.error('Errors while parse notification data!');
    }

    return notification;
  }
}
