import { Component } from '@angular/core';
import { AuthMsalService } from '@app/auth-msal/services/auth-msal.service';
import { SVG_ICON_SETS } from '@shared/constants/svg-icons.constants';
import { SvgIconRegistryService } from 'angular-svg-icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private readonly authMsalService: AuthMsalService,
    private readonly iconReg: SvgIconRegistryService
  ) {
    this.authMsalService.initMsal();
    this.registerSvgIcon();
  }

  private registerSvgIcon(): void {
    SVG_ICON_SETS.forEach(set => {
      set.iconNames.forEach(iconName => {
        this.iconReg.loadSvg(
          `${origin}/${set.iconRoute}/${iconName}.svg`,
          iconName
        );
      });
    });
  }
}
