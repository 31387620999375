import { Inject, Injectable } from '@angular/core';
import { BroadcasterService } from '@avenir-client-web/broadcaster';
import { LoggerService } from '@avenir-client-web/logger';
import { APP_CONFIG, BaseAppConfig } from '@avenir-client-web/models';
import { StorageKey, StorageService } from '@avenir-client-web/storage';
import {
  HubConnectionBuilder,
  IHttpConnectionOptions,
  LogLevel,
} from '@microsoft/signalr';
import { hubInfo } from './constants/signalr.constants';
import { HubType } from './enums/signalr.enums';
import { HubConnection, HubQueryParams } from './models/signalr.models';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  connections: HubConnection = {};

  constructor(
    private readonly storage: StorageService,
    private readonly logger: LoggerService,
    private readonly broadcaster: BroadcasterService,
    @Inject(APP_CONFIG) private readonly config: BaseAppConfig
  ) {}

  startConnection(hubType: HubType, queryParams?: HubQueryParams): void {
    const { hub, connectionUrl } = this.connections[hubType];

    if (hub) return;

    const options: IHttpConnectionOptions = {
      accessTokenFactory: () => this.getAccessToken(),
    };

    const connectionBuilder = new HubConnectionBuilder();

    connectionBuilder.configureLogging(LogLevel.Debug);
    connectionBuilder.withUrl(
      this.buildConnectionUrl(connectionUrl, queryParams),
      options
    );
    connectionBuilder.withAutomaticReconnect();
    this.connections[hubType].hub = connectionBuilder.build();

    this.connections[hubType].hub
      .start()
      .then(() => {
        this.logger.debug('The connection has been successfully established.');
        this.registerToReceiveMessages(hubType);
      })
      .catch(err =>
        this.logger.debug('Error while starting connection: ' + err)
      );
  }

  stopConnection(hubType: HubType): void {
    const { hub } = this.connections[hubType];

    if (!hub) return;

    this.connections[hubType].hub
      .stop()
      .then(() => {
        this.logger.debug('The connection has been successfully stopped.');
        this.connections[hubType].hub = undefined;
      })
      .catch(err =>
        this.logger.debug('Error while stopping connection: ' + err)
      );
  }

  registerToReceiveMessages(hubType: HubType): void {
    const { hub, eventKeys } = this.connections[hubType];

    if (!hub) return;

    eventKeys.forEach(eventKey => {
      hub.on(eventKey, (data: unknown) =>
        this.broadcaster.broadcast(eventKey, data)
      );
    });
  }

  buildHubConnectionInfo(): void {
    Object.keys(hubInfo).forEach(type => {
      if (this.connections[type]) return;

      this.connections[type] = {
        eventKeys: hubInfo[type].eventKeys,
        connectionUrl: `${this.config.serverUrl}/notification/${hubInfo[type].endpoint}`,
        hub: undefined,
      };
    });
  }

  private getAccessToken(): string {
    return this.storage.get(StorageKey.ACCESS_TOKEN);
  }

  private buildConnectionUrl(
    connectionUrl: string,
    queryParams?: HubQueryParams
  ): string {
    if (!queryParams) return connectionUrl;

    return Object.entries(queryParams).reduce(
      (pre, [key, value], index) =>
        `${pre}${!index ? '?' : '&'}${key}=${value}`,
      connectionUrl
    );
  }
}
