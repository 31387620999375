import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRootRoute } from '@core/enums/app-route.enum';
import { ForbiddenPageComponent } from './containers/forbidden-page/forbidden-page.component';
import { UnauthorizedTicketPageComponent } from './containers/unauthorized-ticket-page/unauthorized-ticket-page.component';

const routes: Routes = [
  {
    path: AppRootRoute.FORBIDDEN,
    component: ForbiddenPageComponent,
  },
  {
    path: AppRootRoute.UNAUTHORIZED_TICKET,
    component: UnauthorizedTicketPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GenericPageRoutingModule {}
