import { RelatedContentType } from '@app/content-category/models/content-category.models';
import { BaseAppConfig } from '@avenir-client-web/models';
import { FilterOption, FilterOptionValue } from '@avenir-client-web/search';

export function getSearchFilterOptions(
  config: BaseAppConfig
): FilterOption<FilterOptionValue>[] {
  const filterOptions = [
    {
      label: $localize`search.filter.all`,
      value: FilterOptionValue.ALL,
      visible: config.featureToggle.search.all,
      contentTypes: [RelatedContentType.NONE],
    },
    {
      label: $localize`search.filter.process`,
      value: FilterOptionValue.PROCESS,
      visible: config.featureToggle.search.process,
      contentTypes: [RelatedContentType.PROCESS],
    },
    {
      label: $localize`search.filter.wiki`,
      value: FilterOptionValue.WIKI,
      visible: config.featureToggle.search.wiki,
      contentTypes: [
        RelatedContentType.WIKI_INDEX,
        RelatedContentType.WIKI_DETAIL,
      ],
    },
    {
      label: $localize`search.filter.news`,
      value: FilterOptionValue.NEWS,
      visible: config.featureToggle.search.news,
      contentTypes: [RelatedContentType.NEWS],
    },
    {
      label: $localize`search.filter.people`,
      value: FilterOptionValue.PEOPLE,
      visible: config.featureToggle.search.people,
      contentTypes: [RelatedContentType.PEOPLE],
    },
    {
      label: $localize`search.filter.elearning`,
      value: FilterOptionValue.ELEARNING,
      visible: config.featureToggle.search.learning,
      contentTypes: [RelatedContentType.NONE],
    },
  ];

  return filterOptions.filter(x => x.visible);
}

export function getDefaultFilterOption(
  options: FilterOption<FilterOptionValue>[],
  contentType: RelatedContentType,
  currentOption: FilterOptionValue
): FilterOptionValue {
  if (contentType === RelatedContentType.SEARCH_RESULT) return currentOption;

  const option = options.find(
    x => x.contentTypes && x.contentTypes.some(r => r === contentType)
  );

  return option?.value ?? options[0].value;
}
