import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { SearchService } from '@app/search/services/search.service';
import { ticketPriorityList } from '@app/ticket/constants/ticket.constants';
import { TicketFormKey } from '@app/ticket/enums/ticket.enums';
import { TicketService } from '@app/ticket/services/ticket.service';
import { InfinitySelectConfig } from '@avenir-client-web/base-infinity-select';
import { FormErrorKey } from '@avenir-client-web/enums';
import { AppContentService } from '@core/services/static/app-content.service';
import { titleMaxLength } from '@shared/constants/field-validation.constants';
import { azInfinityConfig } from '@shared/constants/infinity-select-config.constants';
import { NONE_LABEL } from '@shared/constants/label.constants';
import { ContentFilterComponent } from '@shared/content-filter/content-filter.component';
import { debounceTime, merge, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-action-ticket-form',
  templateUrl: './action-ticket-form.component.html',
  styleUrls: ['./action-ticket-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActionTicketFormComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup;

  @ViewChild(ContentFilterComponent) contentFilter: ContentFilterComponent;

  formKey = TicketFormKey;

  titleMaxLength = titleMaxLength;

  formErrorKey = FormErrorKey;

  ticketPriorityLevelList = ticketPriorityList;

  fetchResponsiblePersons$ = new Subject<void>();

  assigneeInfinityConfig: InfinitySelectConfig = {
    ...azInfinityConfig,
    sourceStream$: this.searchService.searchAzUsers.bind(this.searchService),
    defaultOptions: [
      {
        name: NONE_LABEL,
      },
    ],
  };

  get contentCategoryControl(): AbstractControl {
    return this.formGroup?.controls[this.formKey.CONTENT_CATEGORY];
  }

  get contentTypeControl(): AbstractControl {
    return this.formGroup?.controls[this.formKey.CONTENT_TYPE];
  }

  get relatedContentControl(): AbstractControl {
    return this.formGroup?.controls[this.formKey.RELATED_CONTENT];
  }

  get managedByControl(): AbstractControl {
    return this.formGroup?.controls[this.formKey.MANAGED_BY];
  }

  get titleControl(): AbstractControl {
    return this.formGroup?.controls[this.formKey.TITLE];
  }

  get contentControl(): AbstractControl {
    return this.formGroup?.controls[this.formKey.CONTENT];
  }

  get plainContentControl(): AbstractControl {
    return this.formGroup?.controls[this.formKey.PLAIN_CONTENT];
  }

  private readonly compUnsubscribe$ = new Subject<void>();

  constructor(
    private readonly searchService: SearchService,
    private readonly ticketService: TicketService
  ) {}

  ngOnDestroy(): void {
    this.removeControls();
  }

  ngOnInit(): void {
    this.addControls();
  }

  private removeControls(): void {
    [
      this.formKey.CONTENT_CATEGORY,
      this.formKey.CONTENT_TYPE,
      this.formKey.RELATED_CONTENT,
      this.formKey.PRIORITY,
      this.formKey.ASSIGNEE,
      this.formKey.MANAGED_BY,
      this.formKey.PLAIN_CONTENT,
    ].forEach(key => this.formGroup.removeControl(key));
  }

  private addControls(): void {
    [this.formKey.CONTENT_CATEGORY, this.formKey.RELATED_CONTENT].forEach(key =>
      this.formGroup.addControl(
        key,
        new FormControl({
          id: 0,
        })
      )
    );
    [this.formKey.MANAGED_BY, this.formKey.ASSIGNEE].forEach(key =>
      this.formGroup.addControl(key, new FormControl([]))
    );
    this.formGroup.addControl(this.formKey.CONTENT_TYPE, new FormControl(0));
    this.formGroup.addControl(this.formKey.PRIORITY, new FormControl(2));
    this.formGroup.addControl(
      this.formKey.PLAIN_CONTENT,
      new FormControl(undefined)
    );

    this.relatedContentControl.disable();
    setTimeout(() => this.contentFilter.subscribeFieldChanges());

    this.subscribeResponsiblePersonsFetching();

    // First fetching
    AppContentService.resetContent();
    this.fetchResponsiblePersons$.next();
  }

  private subscribeResponsiblePersonsFetching(): void {
    this.fetchResponsiblePersons$
      .pipe(
        debounceTime(500),
        tap(() => this.fetchResponsiblePersons()),
        takeUntil(this.compUnsubscribe$)
      )
      .subscribe();

    merge(
      this.contentCategoryControl.valueChanges,
      this.contentTypeControl.valueChanges,
      this.relatedContentControl.valueChanges
    )
      .pipe(
        tap(() => this.fetchResponsiblePersons$.next()),
        takeUntil(this.compUnsubscribe$)
      )
      .subscribe();
  }

  private fetchResponsiblePersons(): void {
    const apiParams = {
      categoryId: this.contentCategoryControl.value.id,
      type: this.contentTypeControl.value,
      relatedContentId: this.relatedContentControl.value.id,
    };

    this.ticketService
      .getTicketManagers(apiParams)
      .pipe(
        tap(res => this.managedByControl.setValue(res)),
        takeUntil(this.compUnsubscribe$)
      )
      .subscribe();
  }
}
