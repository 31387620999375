import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ShellService } from '@app/shell/services/shell.service';
import { TicketService } from '@app/ticket/services/ticket.service';
import { APP_CONFIG, BaseAppConfig } from '@avenir-client-web/models';
import { ResizeObserverService } from '@avenir-client-web/resize-observer';
import { HubType, SignalRService } from '@avenir-client-web/signalr';
import { CredentialsService } from '@core/authentication/credentials.service';
import { AppRootRoute } from '@core/enums/app-route.enum';
import { PermissionService } from '@core/services/permission.service';
import { GlobalNotificationService } from '@shared/services/global-notification.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit, OnDestroy {
  @ViewChild('scrollableWrapper', { static: true })
  scrollableWrapper: ElementRef<HTMLDivElement>;

  @ViewChild('shellWrapper', { static: true })
  shellWrapper: ElementRef<HTMLDivElement>;

  featureToggle = this.config.featureToggle;

  appSettings = this.config.appSettings;

  private readonly compUnsubscribe$ = new Subject<void>();

  constructor(
    @Inject(APP_CONFIG) readonly config: BaseAppConfig,
    readonly shellService: ShellService,
    private readonly resizeObserverService: ResizeObserverService,
    private readonly permissionService: PermissionService,
    private readonly credentialService: CredentialsService,
    private readonly router: Router,
    private readonly ticketService: TicketService,
    private readonly signalRService: SignalRService,
    private readonly globalNotificationService: GlobalNotificationService
  ) {
    this.signalRService.buildHubConnectionInfo();
  }

  ngOnDestroy(): void {
    this.compUnsubscribe$.next();
    this.compUnsubscribe$.complete();
    this.signalRService.stopConnection(HubType.GLOBAL);
  }

  ngOnInit(): void {
    this.subscribeScrollToTop();
    this.subscribeResizeObserver();
    this.subscribeRoleChanged();
    this.signalRService.startConnection(HubType.GLOBAL);
    this.globalNotificationService.handleNotificationToast();
  }

  onClickNeedHelp(): void {
    this.ticketService.openNeedHelpDialog();
  }

  private subscribeRoleChanged(): void {
    this.credentialService.onRoleSwitched$
      .pipe(takeUntil(this.compUnsubscribe$))
      .subscribe(() => {
        if (!this.permissionService.checkCurrentScreenAccess()) {
          this.router.navigate([AppRootRoute.ERRORS, AppRootRoute.FORBIDDEN], {
            state: { bypassFormGuard: true },
          });
        }
      });
  }

  private subscribeResizeObserver(): void {
    this.resizeObserverService
      .notifyWhenResize(this.shellWrapper)
      .pipe(takeUntil(this.compUnsubscribe$))
      .subscribe(result => {
        this.shellService.resizeSubject$.next(result.contentRect.width);
      });
  }

  private subscribeScrollToTop(): void {
    this.shellService.scrollToTop$
      .pipe(takeUntil(this.compUnsubscribe$))
      .subscribe(() => {
        this.scrollableWrapper.nativeElement.scrollTo(0, 0);
      });
  }
}
