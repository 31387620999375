import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressBarModule as PrimengProgressBarModule } from 'primeng/progressbar';
import { ProgressBarComponent } from './components/progress-bar.component';

@NgModule({
  declarations: [ProgressBarComponent],
  imports: [CommonModule, PrimengProgressBarModule],
  exports: [ProgressBarComponent],
})
export class ProgressBarModule {}
