import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RedirectionBtn } from '@app/generic-page/models/generic-page.model';

@Component({
  selector: 'app-navigation-btn',
  templateUrl: './navigation-btn.component.html',
  styleUrls: ['./navigation-btn.component.scss'],
})
export class NavigationBtnComponent {
  @Input() btn: RedirectionBtn;

  constructor(private readonly router: Router) {}

  onNavigateToPage(): void {
    this.router.navigate(
      ['/', ...this.btn.route.split('/')],
      this.btn.fragment ? { fragment: this.btn.fragment } : {}
    );
  }
}
