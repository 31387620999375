export interface ErrorResponse {
  message: string;
  errorCode: ErrorCode;
}

export enum ErrorCode {
  MAXIMUM_FILE_SIZE = 400001,
  FILE_TYPE_NOT_SUPPORTED = 400002,
  FILE_NOT_EXIST = 404005,
  FILE_UPLOAD_FAILED = 500001,
  INCORRECT_EMAIL = 401001,
  INCORRECT_PASSWORD = 401002,
  NEWS_NOT_EXISTED = 404001,
  WIKI_DETAIL_NOT_EXISTED = 404002,
  CAN_NOT_DELETE_WIKI_PARENT = 400004,
  CAN_NOT_DELETE_CATEGORY = 400005,
  PROCESS_DETAIL_NOT_EXISTED = 404004,
  CHART_INCORRECT_DATA = 500002,
  NOT_EXISTED = 404001,
}
