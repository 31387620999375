import { FileExtension } from '../enums/file-extension.enum';

const documentRoute = 'document';

const previewUrlRegexStrings = [
  `^${location.origin}/${documentRoute}/(.+)/(.+)\\.(.+)`,
  `^${location.origin}/${documentRoute}/(.+)\\?name=(.+)\\.(.+)`,
];

const documentUrlRegexString = `^${location.origin}/${documentRoute}/(.+)`;

const internalUrlRegexString = `^${location.origin}`;

const internalUrlRegex = new RegExp(internalUrlRegexString);

export function shouldPreview(extension: FileExtension): boolean {
  return [
    FileExtension.DOCX,
    FileExtension.DOC,
    FileExtension.XLS,
    FileExtension.XLSX,
    FileExtension.CSV,
    FileExtension.PPT,
    FileExtension.PPTX,
  ].every(i => i !== extension);
}

export function generatePreviewUrl(
  id: string | number,
  name: string,
  extension: FileExtension
): string {
  return `${location.origin}/${documentRoute}/${id}?name=${encodeURIComponent(
    name
  )
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')}.${extension}`;
}

export function getFileExtension(fileName: string): FileExtension {
  return fileName.split('.')?.pop() as FileExtension;
}

export function extractDocumentUrl(url: string): RegExpExecArray {
  return new RegExp(documentUrlRegexString).exec(url);
}

export function extractFileInfoFromUrl(url: string): RegExpExecArray {
  for (let index = 0; index < previewUrlRegexStrings.length; index++) {
    const previewUrlRegex = new RegExp(previewUrlRegexStrings[index]);
    const results = previewUrlRegex.exec(url);

    if (results) return results;
  }

  return null;
}

export function isInternalUrl(url: string): boolean {
  return internalUrlRegex.test(url);
}
