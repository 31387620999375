import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { SearchService } from '@app/search/services/search.service';
import { TicketFormKey } from '@app/ticket/enums/ticket.enums';
import { InfoTicketMetadata } from '@app/ticket/models/ticket.models';
import { TicketService } from '@app/ticket/services/ticket.service';
import { InfinitySelectConfig } from '@avenir-client-web/base-infinity-select';
import { FormErrorKey } from '@avenir-client-web/enums';
import {
  checkMaxLengthControl,
  checkRequiredControls,
} from '@avenir-client-web/form-utils';
import { TenantRole } from '@core/models/role.model';
import {
  linkMaxLength,
  titleMaxLength,
} from '@shared/constants/field-validation.constants';
import { azInfinityConfig } from '@shared/constants/infinity-select-config.constants';
import { UserService } from '@shared/services/user.service';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-info-ticket-form',
  templateUrl: './info-ticket-form.component.html',
  styleUrls: ['./info-ticket-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InfoTicketFormComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup;

  titleMaxLength = titleMaxLength;

  contentMaxLength = 500;

  linkMaxLength = linkMaxLength;

  formKey = TicketFormKey;

  formErrorKey = FormErrorKey;

  recipientsInfinityConfig: InfinitySelectConfig = {
    ...azInfinityConfig,
    sourceStream$: this.searchService.searchAzUsers.bind(this.searchService),
  };

  metadata$: Observable<InfoTicketMetadata>;

  get rolesControl(): AbstractControl {
    return this.formGroup?.controls[this.formKey.ROLES];
  }

  get recipientsControl(): AbstractControl {
    return this.formGroup?.controls[this.formKey.RECIPIENTS];
  }

  get titleControl(): AbstractControl {
    return this.formGroup?.controls[this.formKey.TITLE];
  }

  get contentControl(): AbstractControl {
    return this.formGroup?.controls[this.formKey.CONTENT];
  }

  get linkControl(): AbstractControl {
    return this.formGroup?.controls[this.formKey.LINK];
  }

  private readonly compUnsubscribe$ = new Subject<void>();

  constructor(
    private readonly searchService: SearchService,
    private readonly ticketService: TicketService,
    private readonly userService: UserService
  ) {}

  ngOnDestroy(): void {
    this.removeControls();
  }

  ngOnInit(): void {
    this.addControls();
    this.listenRolesChange();
    this.getMetadata();
  }

  checkFormErrors(): void {
    checkRequiredControls([this.recipientsControl]);
    checkMaxLengthControl(this.linkControl);
  }

  private removeControls(): void {
    [this.formKey.ROLES, this.formKey.RECIPIENTS, this.formKey.LINK].forEach(
      key => this.formGroup.removeControl(key)
    );
  }

  private addControls(): void {
    [this.formKey.ROLES, this.formKey.RECIPIENTS].forEach(key =>
      this.formGroup.addControl(key, new FormControl([]))
    );
    this.formGroup.addControl(this.formKey.LINK, new FormControl(undefined));
  }

  private getMetadata(): void {
    this.metadata$ = this.ticketService
      .getInfoTicketMetadata()
      .pipe(takeUntil(this.compUnsubscribe$));
  }

  private listenRolesChange(): void {
    this.rolesControl.valueChanges
      .pipe(takeUntil(this.compUnsubscribe$))
      .subscribe((roles: TenantRole[]) => this.loadRecipientsByRoles(roles));
  }

  private loadRecipientsByRoles(roles: TenantRole[]): void {
    this.userService
      .getUsersByRoles(roles.map(({ id }) => id))
      .pipe(takeUntil(this.compUnsubscribe$))
      .subscribe(users => this.recipientsControl.setValue(users));
  }
}
