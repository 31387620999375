import { computeMsgId } from '@angular/compiler';
import {
  BLOCK_MARKER,
  ID_SEPARATOR,
  LEGACY_ID_INDICATOR,
  MEANING_SEPARATOR,
} from '../models/constants';
import {
  MessageKey,
  MessageMetadata,
  ParsedMessage,
  ParsedTranslation,
  SourceLocation,
  TargetMessage,
} from '../models/message.model';
import {
  computePlaceholderName,
  parsePlaceholder,
  splitBlock,
} from '../utils/string.function';

/**
 * Translate the text of the `$localizer` tagged-string (i.e. `messageParts` and
 * `substitutions`) using the given `translations`.
 *
 * The tagged-string is parsed to extract its `messageId` which is used to find an appropriate
 * `ParsedTranslation`. If this doesn't match and there are legacy ids then try matching a
 * translation using those.
 *
 * If one is found then it is used to translate the message into a new set of `messageParts` and
 * `substitutions`.
 * The translation may reorder (or remove) substitutions as appropriate.
 *
 * If there is no translation with a matching message id then an error is thrown.
 * If a translation contains a placeholder that is not found in the message being translated then an
 * error is thrown.
 */
export function translate(
  translations: Record<string, ParsedTranslation>,
  messageParts: TemplateStringsArray,
  substitutions: readonly unknown[]
): [TemplateStringsArray, readonly unknown[]] {
  const message = parseMessage(messageParts, substitutions);
  // Look up the translation using the messageId, and then the legacyId if available.
  let translation = translations[message.text];

  // If the messageId did not match a translation, try matching the legacy ids instead
  if (message.legacyIds !== undefined) {
    for (
      let i = 0;
      i < message.legacyIds.length && translation === undefined;
      i++
    ) {
      translation = translations[message.legacyIds[i]];
    }
  }
  if (translation === undefined) {
    throw new MissingTranslationError(message);
  }

  return [
    translation.messageParts,
    translation.placeholderNames.map(placeholder => {
      // eslint-disable-next-line no-prototype-builtins
      if (message.substitutions.hasOwnProperty(placeholder)) {
        return message.substitutions[placeholder];
      } else {
        throw new Error(
          `There is a placeholder name mismatch with the translation provided for the message ${describeMessage(
            message
          )}.\n` +
            `The translation contains a placeholder with name ${placeholder}, which does not exist in the message.`
        );
      }
    }),
  ];
}

/**
 * Parse a `$localizer` tagged string into a structure that can be used for translation or
 * extraction.
 *
 * See `ParsedMessage` for an example.
 */
export function parseMessage(
  messageParts: TemplateStringsArray,
  expressions?: readonly unknown[],
  location?: SourceLocation,
  messagePartLocations?: (SourceLocation | undefined)[],
  expressionLocations: (SourceLocation | undefined)[] = []
): ParsedMessage {
  const substitutions: { [placeholderName: string]: any } = {};
  const substitutionLocations: {
    [placeholderName: string]: SourceLocation | undefined;
  } = {};
  const associatedMessageIds: { [placeholderName: string]: MessageKey } = {};
  const metadata = parseMetadata(messageParts[0], messageParts.raw[0]);
  const cleanedMessageParts: string[] = [metadata.text];
  const placeholderNames: string[] = [];
  let messageString = metadata.text;

  for (let i = 1; i < messageParts.length; i++) {
    const {
      messagePart,
      placeholderName = computePlaceholderName(i),
      associatedMessageId,
    } = parsePlaceholder(messageParts[i], messageParts.raw[i]);

    messageString += `{$${placeholderName}}${messagePart}`;
    if (expressions !== undefined) {
      substitutions[placeholderName] = expressions[i - 1];
      substitutionLocations[placeholderName] = expressionLocations[i - 1];
    }
    placeholderNames.push(placeholderName);
    if (associatedMessageId !== undefined) {
      associatedMessageIds[placeholderName] = associatedMessageId;
    }
    cleanedMessageParts.push(messagePart);
  }
  const messageId =
    metadata.customId || computeMsgId(messageString, metadata.meaning || '');
  const legacyIds = metadata.legacyIds
    ? metadata.legacyIds.filter((id: unknown) => id !== messageId)
    : [];

  return {
    id: messageId,
    legacyIds,
    substitutions,
    substitutionLocations,
    text: messageString,
    customId: metadata.customId,
    meaning: metadata.meaning || '',
    description: metadata.description || '',
    messageParts: cleanedMessageParts,
    messagePartLocations,
    placeholderNames,
    associatedMessageIds,
    location,
  };
}

/**
 * Parse the given message part (`cooked` + `raw`) to extract the message metadata from the text.
 *
 * If the message part has a metadata block this function will extract the `meaning`,
 * `description`, `customId` and `legacyId` (if provided) from the block. These metadata properties
 * are serialized in the string delimited by `|`, `@@` and `␟` respectively.
 *
 * (Note that `␟` is the `LEGACY_ID_INDICATOR` - see `constants.ts`.)
 *
 * For example:
 *
 * ```ts
 * `:meaning|description@@custom-id:`
 * `:meaning|@@custom-id:`
 * `:meaning|description:`
 * `:description@@custom-id:`
 * `:meaning|:`
 * `:description:`
 * `:@@custom-id:`
 * `:meaning|description@@custom-id␟legacy-id-1␟legacy-id-2:`
 * ```
 *
 * @param cooked The cooked version of the message part to parse.
 * @param raw The raw version of the message part to parse.
 * @returns A object containing any metadata that was parsed from the message part.
 */
export function parseMetadata(cooked: string, raw: string): MessageMetadata {
  const { text: messageString, block } = splitBlock(cooked, raw);

  if (block === undefined) {
    return { text: messageString };
  } else {
    const [meaningDescAndId, ...legacyIds] = block.split(LEGACY_ID_INDICATOR);
    const [meaningAndDesc, customId] = meaningDescAndId.split(ID_SEPARATOR, 2);
    let [meaning, description]: (string | undefined)[] = meaningAndDesc.split(
      MEANING_SEPARATOR,
      2
    );

    if (description === undefined) {
      description = meaning;
      meaning = undefined;
    }
    if (description === '') {
      description = undefined;
    }

    return { text: messageString, meaning, description, customId, legacyIds };
  }
}

/**
 * Parse the `messageParts` and `placeholderNames` out of a target `message`.
 *
 * Used by `loadTranslations()` to convert target message strings into a structure that is more
 * appropriate for doing translation.
 *
 * @param message the message to be parsed.
 */
export function parseTranslation(
  messageString: TargetMessage
): ParsedTranslation {
  const parts = messageString.split(/{\$([^}]*)}/);
  const messageParts = [parts[0]];
  const placeholderNames: string[] = [];

  for (let i = 1; i < parts.length - 1; i += 2) {
    placeholderNames.push(parts[i]);
    messageParts.push(`${parts[i + 1]}`);
  }
  const rawMessageParts = messageParts.map(part =>
    part.charAt(0) === BLOCK_MARKER ? '\\' + part : part
  );

  return {
    text: messageString,
    messageParts: makeTemplateObject(messageParts, rawMessageParts),
    placeholderNames,
  };
}

/**
 * Create a `ParsedTranslation` from a set of `messageParts` and `placeholderNames`.
 *
 * @param messageParts The message parts to appear in the ParsedTranslation.
 * @param placeholderNames The names of the placeholders to intersperse between the `messageParts`.
 */
export function makeParsedTranslation(
  messageParts: string[],
  placeholderNames: string[] = []
): ParsedTranslation {
  let messageString = messageParts[0];

  for (let i = 0; i < placeholderNames.length; i++) {
    messageString += `{$${placeholderNames[i]}}${messageParts[i + 1]}`;
  }

  return {
    text: messageString,
    messageParts: makeTemplateObject(messageParts, messageParts),
    placeholderNames,
  };
}

/**
 * Create the specialized array that is passed to tagged-string tag functions.
 *
 * @param cooked The message parts with their escape codes processed.
 * @param raw The message parts with their escaped codes as-is.
 */
export function makeTemplateObject(
  cooked: string[],
  raw: string[]
): TemplateStringsArray {
  Object.defineProperty(cooked, 'raw', { value: raw });

  return cooked as any;
}

function describeMessage(message: ParsedMessage): string {
  const meaningString = message.meaning && ` - "${message.meaning}"`;
  const legacy =
    message.legacyIds && message.legacyIds.length > 0
      ? ` [${message.legacyIds.map((id: unknown) => `"${id}"`).join(', ')}]`
      : '';

  return `"${message.id}"${legacy} ("${message.text}"${meaningString})`;
}

export class MissingTranslationError extends Error {
  private readonly type = 'MissingTranslationError';

  constructor(readonly parsedMessage: ParsedMessage) {
    super(`No translation found for ${describeMessage(parsedMessage)}.`);
  }
}
