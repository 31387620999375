import { Pipe, PipeTransform } from '@angular/core';

export interface ReplaceOptions {
  [key: string]: string;
}

@Pipe({
  name: 'textReplace',
})
export class TextReplacePipe implements PipeTransform {
  transform(value: string, options: ReplaceOptions): string {
    if (!options || typeof options !== 'object') return '';

    Object.entries(options).forEach(
      ([key, val]) => (value = value.replace(new RegExp(`\\${key}`, 'g'), val))
    );

    return value;
  }
}
