<div class="dropdown-container field">
  <label *ngIf="label" class="block" [ngClass]="{ required: hasAsterisk }">{{
    label
  }}</label>
  <p-dropdown
    [options]="(options$ | async) || options"
    [showClear]="false"
    [disabled]="disabled"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [emptyMessage]="emptyMessage"
    dropdownIcon="fa-solid fa-angle-down"
    [styleClass]="styleClass"
    [panelStyleClass]="styleClass"
    (onChange)="onValueChange($event.value); valueChange.emit($event.value)"
    [(ngModel)]="value"
    [virtualScroll]="virtualScroll"
    [scrollHeight]="scrollHeight"
    itemSize="30"
    [filter]="hasFilter"
    [placeholder]="placeholder"
    i18n-filterPlaceholder
    filterPlaceholder="default.search"
    [emptyFilterMessage]="emptyFilterMessage"
    resetFilterOnHide="true"
    autofocusFilter="false"
    (onFilter)="filterChanges$.next($event.filter)"
    (onHide)="onPanelHide()"
    [appendTo]="appendTo"
    [dataKey]="dataKey"
    [autoDisplayFirst]="autoDisplayFirst"
  >
    <ng-template *ngIf="itemTemplate" let-item pTemplate="item">
      <ng-container
        [ngTemplateOutlet]="itemTemplate"
        [ngTemplateOutletContext]="{
          $implicit: item
        }"
      ></ng-container>
    </ng-template>

    <ng-template *ngIf="selectedItemTemplate" let-item pTemplate="selectedItem">
      <ng-container
        [ngTemplateOutlet]="selectedItemTemplate"
        [ngTemplateOutletContext]="{
          $implicit: item
        }"
      ></ng-container>
    </ng-template>
  </p-dropdown>
  <ng-content></ng-content>
</div>
