import { BLOCK_MARKER, ID_SEPARATOR } from '../models/constants';

/**
 * Split a message part (`cooked` + `raw`) into an optional delimited "block" off the front and the
 * rest of the text of the message part.
 *
 * Blocks appear at the start of message parts. They are delimited by a colon `:` character at the
 * start and end of the block.
 *
 * If the block is in the first message part then it will be metadata about the whole message:
 * meaning, description, id.  Otherwise it will be metadata about the immediately preceding
 * substitution: placeholder name.
 *
 * Since blocks are optional, it is possible that the content of a message block actually starts
 * with a block marker. In this case the marker must be escaped `\:`.
 *
 * @param cooked The cooked version of the message part to parse.
 * @param raw The raw version of the message part to parse.
 * @returns An object containing the `text` of the message part and the text of the `block`, if it
 * exists.
 * @throws an error if the `block` is unterminated
 */
export function splitBlock(
  cooked: string,
  raw: string
): { text: string; block?: string } {
  if (raw.charAt(0) !== BLOCK_MARKER) {
    return { text: cooked };
  } else {
    const endOfBlock = findEndOfBlock(cooked, raw);

    return {
      block: cooked.substring(1, endOfBlock),
      text: cooked.substring(endOfBlock + 1),
    };
  }
}

/**
 * Find the end of a "marked block" indicated by the first non-escaped colon.
 *
 * @param cooked The cooked string (where escaped chars have been processed)
 * @param raw The raw string (where escape sequences are still in place)
 *
 * @returns the index of the end of block marker
 * @throws an error if the block is unterminated
 */
export function findEndOfBlock(cooked: string, raw: string): number {
  for (
    let cookedIndex = 1, rawIndex = 1;
    cookedIndex < cooked.length;
    cookedIndex++, rawIndex++
  ) {
    if (raw[rawIndex] === '\\') {
      rawIndex++;
    } else if (cooked[cookedIndex] === BLOCK_MARKER) {
      return cookedIndex;
    }
  }
  throw new Error(`Unterminated $localizer metadata block in "${raw}".`);
}

export function computePlaceholderName(index: number): string {
  return index === 1 ? 'PH' : `PH_${index - 1}`;
}

/**
 * Parse the given message part (`cooked` + `raw`) to extract any placeholder metadata from the
 * text.
 *
 * If the message part has a metadata block this function will extract the `placeholderName` and
 * `associatedMessageId` (if provided) from the block.
 *
 * These metadata properties are serialized in the string delimited by `@@`.
 *
 * For example:
 *
 * ```ts
 * `:placeholder-name@@associated-id:`
 * ```
 *
 * @param cooked The cooked version of the message part to parse.
 * @param raw The raw version of the message part to parse.
 * @returns A object containing the metadata (`placeholderName` and `associatedMessageId`) of the
 *     preceding placeholder, along with the static text that follows.
 */
export function parsePlaceholder(
  cooked: string,
  raw: string
): {
  messagePart: string;
  placeholderName?: string;
  associatedMessageId?: string;
} {
  const { text: messagePart, block } = splitBlock(cooked, raw);

  if (block === undefined) {
    return { messagePart };
  } else {
    const [placeholderName, associatedMessageId] = block.split(ID_SEPARATOR);

    return { messagePart, placeholderName, associatedMessageId };
  }
}
