<div class="field">
  <div class="flex align-items-center justify-content-between">
    <label *ngIf="label" class="block" [ngClass]="{ required: hasAsterisk }"
      >{{ label }}
    </label>
  </div>
  <p-editor
    [readonly]="readonly"
    [placeholder]="placeholder ?? ''"
    class="form-control"
    (onTextChange)="onTextChange($event)"
    [(ngModel)]="value"
    [formats]="formats"
  >
    <ng-template pTemplate="header">
      <span class="ql-formats">
        <select class="ql-header" aria-label="Heading">
          <option value="1">Heading 1</option>
          <option value="2">Heading 2</option>
          <option value="3">Heading 3</option>
        </select>
        <select class="ql-font" aria-label="Font">
          <option value="sans-serif">Sans Serif</option>
          <option value="serif">Serif</option>
          <option value="monospace">Monospace</option>
        </select>
        <button type="button" class="ql-bold" aria-label="Bold"></button>
        <button type="button" class="ql-italic" aria-label="Italic"></button>
        <button
          type="button"
          class="ql-underline"
          aria-label="Underline"
        ></button>
        <select class="ql-color" aria-label="Text Color"></select>
        <select class="ql-background" aria-label="Background Color"></select>
        <button
          type="button"
          class="ql-list"
          value="ordered"
          aria-label="Ordered List"
        ></button>
        <button
          type="button"
          class="ql-list"
          value="bullet"
          aria-label="Bullet List"
        ></button>
        <select class="ql-align" aria-label="Align Text"></select>
        <button type="button" class="ql-link" aria-label="Insert Link"></button>
        <button
          type="button"
          class="ql-image"
          aria-label="Insert Image"
        ></button>
        <button
          type="button"
          class="ql-code-block"
          aria-label="Code Block"
        ></button>
        <button
          type="button"
          class="ql-clean"
          aria-label="Remove Style"
        ></button>
      </span>
    </ng-template>
  </p-editor>
  <ng-content></ng-content>
</div>
