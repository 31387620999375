import { InjectionToken } from '@angular/core';
import { HttpCacheConfig } from '../models/http-cache.models';

const defaultCacheConfig: HttpCacheConfig = {
  expiredIn: 30,
};

export const HTTP_CACHE_CONFIG = new InjectionToken<HttpCacheConfig>(
  'http.cache.config',
  {
    providedIn: 'root',
    factory: () => defaultCacheConfig,
  }
);

export const httpCacheKey = 'Http-Cache';
