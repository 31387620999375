import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpParameterEncoder, HttpService } from '@avenir-client-web/http';
import {
  PagingResponse,
  RequestOption,
  SingleResponse,
} from '@avenir-client-web/models';
import { saveAs } from 'file-saver';
import { Observable, pluck, Subject } from 'rxjs';
import { filesApi } from '../constants/api.constants';
import { FileResponseType } from '../enums/file-response-type.enum';
import { ImageCompressionType } from '../enums/image-compression.enum';
import { FileItem } from '../models/file-item.model';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  uploadFileSuccessSubject$ = new Subject<boolean>();

  uploadFileSuccess$ = this.uploadFileSuccessSubject$.asObservable();

  constructor(private readonly httpService: HttpService) {}

  getDataSource(
    requestOption: RequestOption
  ): Observable<PagingResponse<FileItem>> {
    const url = this.httpService.buildRequestUrl(filesApi, requestOption);

    return this.httpService.get<PagingResponse<FileItem>>(url);
  }

  getFileInfo(fileId: string): Observable<FileItem> {
    return this.httpService
      .get<SingleResponse<FileItem>>(`${filesApi}/${fileId}/info`)
      .pipe(pluck('data'));
  }

  getImages(types: string[]): Observable<FileItem[]> {
    const params = new HttpParams({
      fromObject: {
        types,
        sortBy: 'CreatedDate desc',
      },
      encoder: new HttpParameterEncoder(),
    });

    return this.httpService
      .get<PagingResponse<FileItem>>(filesApi, {
        params,
      })
      .pipe(pluck('data'));
  }

  uploadImage(image: File): Observable<any> {
    const formData = new FormData();

    formData.append('file', image, image.name);

    return this.httpService.post(`${filesApi}/upload-image`, formData);
  }

  uploadDocument(document: File): Observable<any> {
    const formData = new FormData();

    formData.append('file', document, document.name);

    return this.httpService.post(`${filesApi}/upload-document`, formData);
  }

  deleteFile(id: string): Observable<void> {
    return this.httpService.get(`${filesApi}/delete/${id}`);
  }

  deleteFileDummy(id: string): Observable<void> {
    return this.httpService.delete(`${filesApi}/${id}`);
  }

  getFileData(
    fileId: string,
    mimeType: string,
    responseType: FileResponseType,
    compressionType?: ImageCompressionType
  ): Observable<Blob | string> {
    let params = new HttpParams();

    if (compressionType) params = params.set('compressType', compressionType);

    return this.httpService.get<Blob>(`${filesApi}/${fileId}/download`, {
      params,
      headers: { Accept: mimeType },
      observe: 'body',
      responseType,
    });
  }

  downloadFileByBlob(blob: Blob, fileName: string): void {
    saveAs(blob, fileName);
  }
}
