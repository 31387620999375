import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { AppRootRoute } from '@core/enums/app-route.enum';
import { ProfileService } from '@core/services/profile.service';

@Injectable({
  providedIn: 'root',
})
export class UserActiveGuard implements CanActivate, CanLoad {
  constructor(
    private readonly profileService: ProfileService,
    private readonly router: Router
  ) {}

  canActivate(): boolean {
    if (this.profileService.getUserActiveState()) {
      this.router.navigate([AppRootRoute.HOME]);
    }

    return !this.profileService.getUserActiveState();
  }

  canLoad(): boolean {
    return !this.profileService.getUserActiveState();
  }
}
