<a class="header-profile" (click)="menu.toggle($event)">
  <app-avatar
    [avatarUrl]="avatarUrl"
    class="avatar"
    shape="circle"
  ></app-avatar>

  <span class="title" [class.show-always]="alwaysShowTitle">{{
    profile?.name
  }}</span>
</a>

<p-tieredMenu
  #menu
  [model]="items"
  [popup]="true"
  appendTo="body"
></p-tieredMenu>
