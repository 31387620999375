import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@avenir-client-web/button';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerComponent } from './pdf-viewer.component';

@NgModule({
  imports: [CommonModule, ButtonModule, NgxExtendedPdfViewerModule],
  declarations: [PdfViewerComponent],
  exports: [PdfViewerComponent],
})
export class PdfViewerModule {}
