<button
  pButton
  [ngClass]="['btn', styleClass]"
  [type]="type"
  [label]="label"
  [disabled]="disabled"
  (click)="onClick()"
  [icon]="piIcon"
  [iconPos]="iconPos"
></button>
