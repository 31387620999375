export enum SidebarItemKey {
  DASHBOARD = 'dashboard',
  NEWS = 'news',
  WIKIS = 'wikis',
  PROCESSES = 'processes',
  FILES = 'files',
  CONTENT_CATEGORIES = 'contentCategories',
  QUICKLINKS = 'quicklinks',
  LANGUAGES = 'languages',
  TICKET_OVERVIEW = 'ticketOverview',
  TICKET_MANAGEMENT = 'ticketManagement',
  SEARCH_KEYWORDS = 'searchKeywords',
}
