import { Observable } from 'rxjs';

export interface InfinitySelectConfig {
  requestOption?: unknown;
  filterChange?: (requestOption: unknown, filterValue: string) => void;
  nextPage?: (requestOption: unknown, response?: any) => void;
  takeWhile?: (response: any) => boolean;
  mapData?: (response: any) => unknown[];
  defaultOptions?: unknown[];
  sourceStream$: (requestOption: unknown) => Observable<unknown>;
}

export interface RootComponent {
  activateFilter: () => void;
}
