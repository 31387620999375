import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInfinitySelect } from '@avenir-client-web/base-infinity-select';
import { SelectItem } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent
  extends BaseInfinitySelect
  implements OnInit, OnDestroy
{
  @Input() scrollHeight = '200px';

  @Input() virtualScroll = false;

  @Input() hasFilter = false;

  @Input() emptyMessage = $localize`default.dropdown.empty`;

  @Input() itemTemplate: TemplateRef<unknown>;

  @Input() selectedItemTemplate: TemplateRef<unknown>;

  @Input() autoDisplayFirst = true;

  @Output() valueChange = new EventEmitter<unknown>();

  @ViewChild(Dropdown, { static: true }) dropdown: Dropdown;

  override ngOnInit(): void {
    this.customPrimeNgFunctions();
    this.setRootComponent(this.dropdown);
    this.setWrapperClass('p-dropdown-virtualscroll');
    super.ngOnInit();
  }

  private customPrimeNgFunctions(): void {
    this.dropdown.findOption = (
      val: SelectItem,
      opts: SelectItem[],
      inGroup?: boolean
    ): SelectItem => {
      if (this.dropdown.group && !inGroup) {
        return this.dropdown.findOption(val, opts, inGroup);
      } else {
        const index = this.dropdown.findOptionIndex(val, opts);

        return index !== -1 ? opts[index] : val;
      }
    };
  }
}
