import { Directive, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Directive()
export class BaseControlComponent<T = any> implements ControlValueAccessor {
  @Input() placeholder: string;

  @Input() label: string;

  @Input() hasAsterisk = false;

  @Input() value: T;

  @Input() disabled = false;

  @Input() readonly = false;

  @Input() appendTo: unknown;

  private onChange: (value: T) => void;

  private onTouched: () => void;

  writeValue(value: T): void {
    this.value = value;
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onValueChange(value: T): void {
    if (this.onChange) {
      this.onChange(value);
      this.writeValue(value);
    }
  }
}
