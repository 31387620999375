<div [class.hidden]="hasError || isLoading" class="background">
  <img
    #image
    [class.hidden]="isSvg"
    (error)="onChangeErrorState(true)"
    (load)="onChangeErrorState(false)"
    [alt]="alt ?? ''"
    [ngClass]="styleClass"
    [src]="imageUrl$ | async"
  />
</div>

<div class="image-error" [ngClass]="errorImageStyleClass" *ngIf="hasError">
  <span class="pi pi-image" style="font-size: 2rem"></span>
</div>

<div *ngIf="isLoading" class="skeleton">
  <p-skeleton height="100%" width="100%"></p-skeleton>
</div>
