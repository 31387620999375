import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, filter, Observable, of, tap, throwError } from 'rxjs';
import { httpCacheKey } from '../constants/http-cache.constants';
import { HttpCacheRequest } from '../models/http-cache.models';
import { HttpCacheService } from '../services/http-cache.service';

@Injectable()
export class HttpCacheInterceptor implements HttpInterceptor {
  constructor(private readonly httpCache: HttpCacheService) {}

  intercept(
    req: HttpCacheRequest,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (req.method !== 'GET') return next.handle(req);

    if (this.httpCache.hasCache(req.url))
      return of(this.httpCache.getCache(req.url).clone());

    return next.handle(req).pipe(
      filter(event => event instanceof HttpResponse),
      tap(
        event =>
          req.headers.has(httpCacheKey) &&
          this.httpCache.cache(req.url, event as HttpResponse<unknown>)
      ),
      catchError(error => throwError(() => error))
    );
  }
}
