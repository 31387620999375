import {
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInfinitySelect } from '@avenir-client-web/base-infinity-select';
import { MultiSelect } from 'primeng/multiselect';

@Component({
  selector: 'app-infinity-multi-select',
  templateUrl: './infinity-multi-select.component.html',
  styleUrls: ['./infinity-multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InfinityMultiSelectComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class InfinityMultiSelectComponent
  extends BaseInfinitySelect
  implements OnInit, OnDestroy
{
  @Input() noEllipsis = true;

  @ViewChild(MultiSelect, { static: true })
  multiSelect: MultiSelect;

  override ngOnInit(): void {
    this.setRootComponent(this.multiSelect);
    this.setWrapperClass('infinity-multi-select');
    super.ngOnInit();
  }

  removeItem(dataKey: string | number): void {
    const value = this.value.filter(
      (item: unknown) => item[this.dataKey] !== dataKey
    );

    this.onValueChange(value);
  }
}
