<div class="search-box-container">
  <div class="search-box">
    <span class="search-box-field p-input-icon-left">
      <span class="search-box-field__icon">
        <svg-icon name="search" class="stroked"></svg-icon>
      </span>

      <form action="" (ngSubmit)="emitLoadEvent()">
        <input
          class="search-box-field__input"
          type="search"
          name="search"
          pInputText
          [placeholder]="placeholder"
          [(ngModel)]="keyword"
          [maxlength]="inputMaxLength"
          autocomplete="off"
        />
      </form>

      <button
        class="search-box-field__button p-button-rounded p-button-icon-only"
        (click)="emitLoadEvent()"
        [disabled]="!keyword?.length && !isAllowedSearchEmpty"
        pButton
        pRipple
        type="button"
      >
        <svg-icon name="arrow-right"></svg-icon>
      </button>
    </span>
  </div>
</div>
