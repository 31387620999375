import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { isObservable, Subject, takeUntil } from 'rxjs';
import { ComponentLoaderDirective } from './component-loader.directive';
import { ComponentLoaderConfig } from './component-loader.models';

@Component({
  selector: 'app-component-loader',
  templateUrl: './component-loader.component.html',
})
export class ComponentLoaderComponent<T = unknown>
  implements OnChanges, OnDestroy
{
  @Input() component: ComponentLoaderConfig;

  @ViewChild(ComponentLoaderDirective, { static: true })
  componentLoader: ComponentLoaderDirective;

  compInstance: T;

  private readonly compUnsubscribe$ = new Subject<void>();

  ngOnDestroy(): void {
    this.compUnsubscribe$.next();
    this.compUnsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes.component && this.loadComponent();
  }

  private loadComponent(): void {
    if (!this.component) return;

    const viewContainerRef = this.componentLoader.viewContainerRef;

    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<unknown>(
      this.component.componentType
    );

    this.compInstance = componentRef.instance as T;
    this.bindComponentParams();
    this.bindOutputEvents();
  }

  private bindComponentParams(): void {
    if (!this.component.params) return;

    Object.entries(this.component.params).forEach(
      ([key, value]) => (this.compInstance[key] = value)
    );
  }

  private bindOutputEvents(): void {
    if (!this.component.outputEvents) return;

    Object.entries(this.component.outputEvents).forEach(
      ([eventKey, executor]) => {
        const emitter = this.compInstance[eventKey];

        if (!emitter || !isObservable(emitter)) return;

        emitter
          .pipe(takeUntil(this.compUnsubscribe$))
          .subscribe(executor.bind(this.component.hostBinding));
      }
    );
  }
}
