<div class="main-header-container">
  <app-header-profile
    [profile]="profile"
    [items]="items"
    [avatarUrl]="avatarUrl$ | async"
  ></app-header-profile>
  <div class="header-tools">
    <div
      *ngIf="featureToggle.tickets"
      class="bell"
      [class.has-unread]="hasUnread$ | async"
      [routerLink]="['/', appRootRoute.NOTIFICATIONS]"
      (click)="onBellClick()"
    >
      <svg-icon name="bell" class="stroked icon-medium"></svg-icon>
    </div>
    <div class="role-btn">
      <svg-icon
        name="change-role"
        class="stroked icon-medium"
        (click)="menu.toggle($event)"
      ></svg-icon>
    </div>
    <div
      class="search-tool"
      [class.active]="isSearchActive"
      #searchTool
      *ngIf="
        (hideTopBarSearch$ | async) === false && searchFilterOptions.length > 0
      "
    >
      <div class="search-btn">
        <svg-icon name="search" class="stroked icon-medium"></svg-icon>
      </div>
      <div class="search-bar">
        <app-search-box
          i18n-placeholder
          placeholder="search.input.placeholder"
          (loadEvent)="navigateToSearchResultsPage($event)"
          [filter]="filter"
        ></app-search-box>
      </div>
    </div>
  </div>
</div>

<p-tieredMenu
  #menu
  [model]="roleItems"
  [popup]="true"
  appendTo="body"
></p-tieredMenu>
