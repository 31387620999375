import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, first, throwError } from 'rxjs';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { BackwardConfirmationComponent } from '../../../../../backward-confirmation/src';
import { FileType } from '../../enums/file-type.enum';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent {
  acceptImageFileTypes: string[] = [FileType.JPEG, FileType.PNG, FileType.SVG];

  acceptDocumentFileTypes = [
    FileType.PDF,
    FileType.DOC,
    FileType.DOCX,
    FileType.XLS,
    FileType.XLSX,
    FileType.CSV,
    FileType.PPT,
    FileType.PPTX,
  ];

  acceptFileTypes = [
    ...this.acceptImageFileTypes,
    ...this.acceptDocumentFileTypes,
  ];

  selectedFile: File;

  private readonly maxFileSize = 10485760;

  constructor(
    private readonly messageService: MessageService,
    private readonly dialogService: DialogService,
    private readonly dialogRef: DynamicDialogRef,
    private readonly fileService: FileService
  ) {}

  handleFileInput(event: { target: HTMLInputElement }): void {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    if (!this.acceptFileTypes.includes(file.type)) {
      this.showIncorrectFileTypeMessage();
      event.target.value = null;

      return;
    }

    if (file.size > this.maxFileSize) {
      this.showIncorrectFileSizeMessage();
      event.target.value = null;

      return;
    }

    this.selectedFile = file;
  }

  save(): void {
    if (!this.selectedFile) {
      return;
    }

    let uploadObs$ = this.fileService.uploadDocument(this.selectedFile);

    if (this.isImageFile()) {
      uploadObs$ = this.fileService.uploadImage(this.selectedFile);
    }

    uploadObs$
      .pipe(
        catchError(error => {
          this.showFailedUploadMessage();

          return throwError(() => error);
        })
      )
      .subscribe(() => {
        this.showSuccessUploadMessage();
        this.fileService.uploadFileSuccessSubject$.next(true);
      });

    this.dialogRef.close();
  }

  close(): void {
    if (this.selectedFile) {
      const confirmDialogRef = this.dialogService.open(
        BackwardConfirmationComponent,
        {
          closable: false,
          closeOnEscape: false,
          showHeader: false,
          styleClass: 'confirmation-dialog',
        }
      );

      confirmDialogRef.onClose.pipe(first()).subscribe((isConfirm: boolean) => {
        if (isConfirm) {
          this.dialogRef.close();
        }
      });

      return;
    }

    this.dialogRef.close();
  }

  private isImageFile(): boolean {
    return this.acceptImageFileTypes.includes(this.selectedFile.type);
  }

  private showSuccessUploadMessage(): void {
    this.messageService.add({
      severity: 'success',
      summary: $localize`uploadFile.uploadSuccess`,
    });
  }

  private showFailedUploadMessage(): void {
    this.messageService.add({
      severity: 'error',
      summary: $localize`uploadFile.uploadFailed`,
    });
  }

  private showIncorrectFileTypeMessage(): void {
    this.messageService.add({
      severity: 'error',
      summary: $localize`uploadFile.incorrectFileType`,
    });
  }

  private showIncorrectFileSizeMessage(): void {
    this.messageService.add({
      severity: 'error',
      summary: $localize`uploadFile.incorrectFileSize`,
    });
  }
}
