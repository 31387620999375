import { Directive, OnDestroy, OnInit } from '@angular/core';
import { CredentialsService } from '@core/authentication/credentials.service';
import { FormChanges } from '@core/models/form-changes.model';
import { TenantRole } from '@core/models/role.model';
import { LeaveDialogService } from '@core/services/leave-dialog.service';
import { PermissionService } from '@core/services/permission.service';
import { filter, Subject, takeUntil, tap } from 'rxjs';

@Directive()
export abstract class BaseComponent implements OnInit, OnDestroy {
  protected component: FormChanges;

  protected readonly roleChanged$ = new Subject<void>();

  protected readonly compUnsubscribe$ = new Subject<void>();

  protected constructor(
    protected readonly credentialsService: CredentialsService,
    protected readonly permissionService: PermissionService,
    protected readonly leaveDialogService?: LeaveDialogService
  ) {
    credentialsService.onRoleSwitched$
      .pipe(
        takeUntil(this.compUnsubscribe$),
        tap(() => this.ngOnInit())
      )
      .subscribe();

    credentialsService.beforeChangeRole$
      .pipe(
        takeUntil(this.compUnsubscribe$),
        tap(role => {
          if (this.component) {
            const hasChange = this.component.hasMadeChanges();

            if (hasChange) {
              this.leaveDialogService
                .showLeaveConfirmationDialog()
                .onClose.pipe(
                  filter(res => !!res),
                  takeUntil(this.compUnsubscribe$)
                )

                .subscribe(action => {
                  if (action) {
                    this.setRole(role);
                  }
                });
            } else {
              this.setRole(role);
            }
          } else {
            this.setRole(role);
          }
        })
      )
      .subscribe();
  }

  protected setRole(role: TenantRole): void {
    this.permissionService
      .setUserRole(role)
      .pipe(takeUntil(this.compUnsubscribe$))
      .subscribe();
  }

  ngOnInit(): void {
    return undefined;
  }

  ngOnDestroy(): void {
    this.compUnsubscribe$.next();
    this.compUnsubscribe$.complete();
  }
}
