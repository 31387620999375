import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from '../../../base-control/base-control.component';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
  ],
})
export class TextAreaComponent extends BaseControlComponent<string> {
  @Input() rows: number;

  @Input() autoResize = false;

  @Input() maxLength: number;

  @Input() autoTrim = true;

  onBlur(): void {
    if (this.autoTrim) {
      this.onValueChange(this.value?.trim());
    }
  }
}
