import { Pipe, PipeTransform } from '@angular/core';
import { ToastType } from './toast.constants';

@Pipe({
  name: 'iconByType',
})
export class IconByTypePipe implements PipeTransform {
  transform(value: ToastType): string {
    switch (value) {
      case ToastType.SUCCESS:
        return 'check';
      case ToastType.INFO:
        return 'bell';
      case ToastType.ERROR:
        return 'times-circle';
      default:
        return 'bell';
    }
  }
}
