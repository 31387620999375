import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerService } from '@avenir-client-web/logger';
import { ErrorCode } from '@avenir-client-web/models';
import { AppRootRoute } from '@core/enums/app-route.enum';
import { environment } from '@environments/environment';
import { MessageService } from 'primeng/api';
import { catchError, Observable } from 'rxjs';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private readonly logger: LoggerService,
    private readonly messageService: MessageService,
    private readonly router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.error instanceof Blob) {
          return this.parseErrorBlob(errorResponse);
        }

        return this.errorHandler(errorResponse, request);
      })
    );
  }

  // Customize the default error handler here if needed
  private errorHandler(
    errorResponse: HttpErrorResponse,
    request: HttpRequest<unknown>
  ): Observable<HttpEvent<unknown>> {
    if (!environment.production) {
      this.logger.error('Request error', errorResponse.status, request.url);
    }

    throw this.handleSystemError(errorResponse);
  }

  private parseErrorBlob(
    errorResponse: HttpErrorResponse
  ): Observable<HttpEvent<unknown>> {
    const reader: FileReader = new FileReader();

    const osv = new Observable<HttpEvent<unknown>>(observer => {
      reader.onloadend = () => {
        observer.error(JSON.parse(reader.result.toString()));
        observer.complete();
      };
    });

    reader.readAsText(errorResponse.error);

    return osv;
  }

  private handleSystemError(
    errorResponse: HttpErrorResponse
  ): HttpErrorResponse {
    switch (errorResponse.status) {
      case 400:
        this.handle400Error(errorResponse);
        break;
      case 401:
        this.handle401Error();
        break;
      case 403:
        this.handle403Error();
        break;
      case 404:
        this.handle404Error(errorResponse);
        break;
      case 500:
        this.handle500Error();
        break;
    }
    throw errorResponse;
  }

  private handleNotExistedError(): void {
    this.router.navigate([AppRootRoute.ERRORS, AppRootRoute.FORBIDDEN]);
  }

  private handle400Error(errorResponse: HttpErrorResponse): void {
    const { error } = errorResponse;

    if (error?.statusCode !== error?.errorCode && error?.errorCode) {
      throw error;
    }

    this.messageService.add({
      severity: 'error',
      summary: errorResponse.error,
    });
  }

  private handle401Error(): void {
    this.messageService.add({
      severity: 'error',
      summary: $localize`error.sessionExpired`,
    });
  }

  private handle403Error(): void {
    this.messageService.add({
      severity: 'error',
      summary: $localize`error.notAuthorizedAccessData`,
    });
  }

  private handle404Error(errorResponse: HttpErrorResponse): void {
    if (errorResponse.error) {
      let { error } = errorResponse;

      try {
        error = JSON.parse(error);
      } catch (err) {
        // Do nothing
      }

      if (error.errorCode === ErrorCode.FILE_NOT_EXIST) return;
    }
    this.handleNotExistedError();
  }

  private handle500Error(): void {
    this.messageService.add({
      severity: 'error',
      summary: $localize`error.internalServerError`,
    });
  }
}
