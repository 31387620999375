<p-toast #toast class="custom-toast">
  <ng-template let-message pTemplate="message">
    <div
      class="content"
      [routerLink]="message?.data?.route"
      [class.has-link]="!!message?.data?.route"
      (click)="toastService.click$.next(message?.data?.route)"
    >
      <svg-icon
        [name]="message?.severity | iconByType"
        class="stroked icon-medium icon"
      ></svg-icon>
      <span class="message">{{ message?.summary }}</span>
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-times"
        class="p-button-rounded p-button-text close-button"
        (click)="onClose($event, toast, message)"
      ></button>
    </div>
  </ng-template>
</p-toast>
