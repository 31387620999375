<ng-template #loaded>
  <div [ngClass]="containerClass()" class="avatar">
    <img
      [src]="avatarUrl"
      alt="avatar"
      (error)="fallbackImage()"
      *ngIf="hasImage; else defaultImage"
    />

    <ng-template #defaultImage>
      <i class="fa-solid fa-user"></i>
    </ng-template>
  </div>
</ng-template>

<div
  *ngIf="!avatarUrl; else loaded"
  [ngClass]="containerClass()"
  class="skeleton"
>
  <p-skeleton height="100%" width="100%"></p-skeleton>
</div>
