<form [formGroup]="formGroup" class="action-ticket-form">
  <app-content-filter
    [contentFilterForm]="formGroup"
    class="content-filter"
  ></app-content-filter>

  <app-dropdown
    class="priority"
    [hasAsterisk]="true"
    [formControlName]="formKey.PRIORITY"
    [options]="ticketPriorityLevelList"
    optionLabel="label"
    optionValue="value"
    i18n-label
    label="ticket.priority"
  >
  </app-dropdown>

  <app-dropdown
    [formControlName]="formKey.ASSIGNEE"
    i18n-label
    label="ticket.assignee"
    optionLabel="name"
    [hasFilter]="true"
    emptyMessage="default.enterToSearch"
    i18n-emptyMessage
    [virtualScroll]="true"
    [infinityConfig]="assigneeInfinityConfig"
    appendTo="body"
    dataKey="aadId"
  >
  </app-dropdown>

  <app-infinity-multi-select
    [formControlName]="formKey.MANAGED_BY"
    class="managed-by"
    i18n-label
    label="ticket.managedBy"
    optionLabel="name"
    [readonly]="true"
  >
  </app-infinity-multi-select>

  <app-input
    class="ticket-title"
    [formControlName]="formKey.TITLE"
    i18n-label
    label="ticket.title"
    i18n-placeholder
    placeholder="ticket.title.placeholder"
    [maxLength]="titleMaxLength"
    [hasAsterisk]="true"
  >
    <small
      *ngIf="titleControl?.hasError(formErrorKey.REQUIRED)"
      class="p-error block"
      i18n
      >default.error.required
    </small>
  </app-input>

  <app-editor
    class="ticket-content"
    [formControlName]="formKey.CONTENT"
    i18n-placeholder
    placeholder="ticket.content.placeholder"
    i18n-label
    label="ticket.content"
    [hasAsterisk]="true"
    (textChanged)="plainContentControl?.setValue($event.textValue)"
  >
    <small
      *ngIf="contentControl?.hasError(formErrorKey.REQUIRED)"
      class="p-error block"
      i18n
      >default.error.required
    </small>
  </app-editor>
</form>
