import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ButtonModule } from 'primeng/button';
import { MailToButtonComponent } from './mail-to-button.component';

@NgModule({
  declarations: [MailToButtonComponent],
  imports: [CommonModule, ButtonModule, AngularSvgIconModule],
  exports: [MailToButtonComponent],
})
export class MailToButtonModule {}
