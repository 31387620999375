import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LinkActionHandlerDirective } from './link-action-handler.directive';

@NgModule({
  declarations: [LinkActionHandlerDirective],
  imports: [CommonModule],
  exports: [LinkActionHandlerDirective],
})
export class LinkActionHandlerModule {}
