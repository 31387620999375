<app-dialog-content-layout
  class="ticket-form"
  (closeEvent)="close()"
  (saveEvent)="save()"
>
  <span class="title" i18n>openTicket.createTitle</span>
  <form [formGroup]="ticketForm" class="mb-5">
    <div class="type-wrapper">
      <app-radio-button
        [name]="formKey.TYPE"
        i18n-label
        label="ticket.type"
        [hasAsterisk]="true"
        [formControlName]="formKey.TYPE"
        [options]="typeOptions"
      >
      </app-radio-button>
    </div>
    <app-component-loader [component]="compLoaderConfig"></app-component-loader>
  </form>
</app-dialog-content-layout>
