import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
} from '@angular/core';
import { translate } from '../utils/translate.function';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[localizer]' })
export class LocalizerDirective implements OnChanges {
  @Input() set localizer(key: string) {
    if (!key) return;

    this.r2.setProperty(this.el.nativeElement, 'textContent', translate(key));
  }

  constructor(
    private readonly el: ElementRef<HTMLElement>,
    private readonly r2: Renderer2
  ) {}

  ngOnChanges(): void {
    this.localizer = this.el.nativeElement.textContent;
  }
}
