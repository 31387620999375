import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { RelatedContent } from '@app/content-category/models/content-category.models';
import { contentTypeList } from '@app/ticket/constants/ticket.constants';
import { TicketFormKey } from '@app/ticket/enums/ticket.enums';
import { RelatedContentRequest } from '@app/ticket/models/ticket.models';
import { TicketService } from '@app/ticket/services/ticket.service';
import { FormErrorKey } from '@avenir-client-web/enums';
import { RequestOption } from '@avenir-client-web/models';
import { MetadataItem } from '@core/models/metadata-item.model';
import { NONE_LABEL } from '@shared/constants/label.constants';
import {
  filter,
  map,
  Observable,
  pluck,
  startWith,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';

@Component({
  selector: 'app-content-filter',
  templateUrl: './content-filter.component.html',
  styleUrls: ['./content-filter.component.scss'],
})
export class ContentFilterComponent implements OnInit {
  @Input() contentFilterForm: FormGroup;

  @Input() disabled = false;

  formKey = TicketFormKey;

  metadata$: Observable<MetadataItem[]>;

  contentTypeList = contentTypeList;

  relatedContentList: RelatedContent[] = [
    {
      id: 0,
      title: NONE_LABEL,
    } as RelatedContent,
  ];

  formErrorKey = FormErrorKey;

  private readonly relatedContentRequest: RelatedContentRequest = {
    categoryId: 0,
    contentType: 0,
  };

  private readonly loader$ = new Subject<boolean>();

  private readonly compUnsubscribe$ = new Subject<void>();

  private selectedContent: RelatedContent;

  get contentCategoryControl(): AbstractControl {
    return this.contentFilterForm?.controls[this.formKey.CONTENT_CATEGORY];
  }

  get contentTypeControl(): AbstractControl {
    return this.contentFilterForm?.controls[this.formKey.CONTENT_TYPE];
  }

  get relatedContentControl(): AbstractControl {
    return this.contentFilterForm?.controls[this.formKey.RELATED_CONTENT];
  }

  constructor(private readonly ticketService: TicketService) {
    this.subscribeLoader();
  }

  ngOnInit(): void {
    this.getMetadata();
  }

  subscribeFieldChanges(): void {
    this.contentCategoryControl.valueChanges
      .pipe(
        startWith(this.contentCategoryControl?.value),
        takeUntil(this.compUnsubscribe$)
      )
      .subscribe(({ id: categoryId }) => {
        if (categoryId === this.selectedContent?.contentCategory?.id) {
          this.relatedContentList = this.relatedContentList.filter(
            ({ contentCategory, id }) =>
              contentCategory?.id === categoryId || id === 0
          );

          return;
        }

        setTimeout(
          () =>
            !this.contentFilterForm.pristine &&
            this.relatedContentControl.setValue({ id: 0 }, { emitEvent: false })
        );
        this.selectedContent = null;
        this.onCategoryChange(categoryId);
      });

    this.contentTypeControl.valueChanges
      .pipe(
        startWith(this.contentTypeControl?.value),
        takeUntil(this.compUnsubscribe$)
      )
      .subscribe((contentType: number) => {
        setTimeout(
          () =>
            !this.contentFilterForm.pristine &&
            this.relatedContentControl.setValue({ id: 0 }, { emitEvent: false })
        );
        this.selectedContent = null;
        this.onContentTypeChange(contentType);
      });

    this.relatedContentControl.valueChanges
      .pipe(
        startWith(this.relatedContentControl?.value?.id),
        takeUntil(this.compUnsubscribe$)
      )
      .subscribe((relatedContent: RelatedContent) => {
        if (!relatedContent?.id) return;

        const { contentCategory } = relatedContent;

        this.selectedContent = relatedContent;

        contentCategory &&
          this.contentCategoryControl.setValue({
            id: contentCategory.id,
          });
      });
  }

  private onContentTypeChange(contentType: number): void {
    this.relatedContentRequest.contentType = contentType;
    if (contentType === 0) {
      this.relatedContentControl.setValue({ id: 0 });

      contentType
        ? this.relatedContentControl.enable({ emitEvent: false })
        : this.relatedContentControl.disable({ emitEvent: false });

      return;
    }

    this.fetchRelatedContent();
  }

  private onCategoryChange(categoryId: number): void {
    this.relatedContentRequest.categoryId = categoryId;
    this.fetchRelatedContent();
  }

  private getMetadata(): void {
    this.metadata$ = this.ticketService.getMetadata().pipe(
      map(res => {
        return [
          {
            id: 0,
            name: NONE_LABEL,
          },
          ...res,
        ];
      }),
      tap(() => {
        this.fetchRelatedContent();
      }),
      takeUntil(this.compUnsubscribe$)
    );
  }

  private fetchRelatedContent(): void {
    if (!this.relatedContentRequest.contentType) return;
    this.loader$.next(true);

    this.getRelatedContent(this.relatedContentRequest)
      .pipe(
        map(res => {
          return [
            <RelatedContent>{
              id: 0,
              contentCategoryId: this.relatedContentRequest.categoryId,
              title: NONE_LABEL,
              type: this.relatedContentRequest.contentType,
            },
            ...res,
          ];
        }),
        tap(relatedContents => {
          this.loader$.next(false);

          const selectedContent = this.getSelectedContent(relatedContents);

          this.relatedContentControl.setValue(selectedContent);
          this.relatedContentList = relatedContents;
        }),
        takeUntil(this.compUnsubscribe$)
      )
      .subscribe();
  }

  private getSelectedContent(
    relatedContents: RelatedContent[]
  ): RelatedContent {
    if (this.selectedContent) return this.selectedContent;

    const { relatedContent } = this.contentFilterForm.getRawValue();

    return relatedContent ?? relatedContents[0];
  }

  private getRelatedContent({
    contentType,
    categoryId,
  }: RelatedContentRequest): Observable<RelatedContent[]> {
    const requestOption: RequestOption = {
      filter: {
        type: contentType,
        categoryId,
      },
    };

    return this.ticketService
      .getRelatedContent(requestOption)
      .pipe(pluck('data'));
  }

  private subscribeLoader(): void {
    this.loader$
      .pipe(
        filter(() => !this.disabled),
        tap(res => {
          if (res) {
            this.relatedContentControl.disable({ emitEvent: false });
          } else {
            this.relatedContentControl.enable({ emitEvent: false });
          }
        }),
        takeUntil(this.compUnsubscribe$)
      )
      .subscribe();
  }
}
