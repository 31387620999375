import { Component, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { User } from '@core/models/profile.model';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss'],
})
export class HeaderProfileComponent {
  @Input() profile: User;

  @Input() items: MenuItem[] = [];

  @Input() alwaysShowTitle = true;

  @Input() avatarUrl: SafeUrl;
}
