<form class="content-filter-form" [formGroup]="contentFilterForm">
  <app-dropdown
    class="content-category"
    [formControlName]="formKey.CONTENT_CATEGORY"
    [options]="metadata$ | async"
    i18n-label
    label="needHelp.contentCategory"
    optionLabel="name"
    [hasFilter]="true"
    [disabled]="disabled"
    appendTo="body"
    dataKey="id"
  >
  </app-dropdown>
  <app-dropdown
    class="content-type"
    [formControlName]="formKey.CONTENT_TYPE"
    [options]="contentTypeList"
    i18n-label
    label="needHelp.contentType"
    optionLabel="label"
    optionValue="value"
    [disabled]="disabled"
    appendTo="body"
  >
  </app-dropdown>

  <app-dropdown
    class="related-content"
    [formControlName]="formKey.RELATED_CONTENT"
    [options]="relatedContentList"
    i18n-label
    label="needHelp.relatedContent"
    optionLabel="title"
    [hasFilter]="true"
    [disabled]="disabled"
    dataKey="id"
    appendTo="body"
  >
  </app-dropdown>
</form>
