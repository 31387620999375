import { Injectable } from '@angular/core';
import { ProfileDetail } from '@app/profile/models/profile.models';
import { SearchCountsResponse } from '@app/search/models/search.models';
import { SearchMapperService } from '@app/search/services/search-mapper.service';
import { HttpService } from '@avenir-client-web/http';
import {
  PagingResponse,
  RequestOption,
  SingleResponse,
} from '@avenir-client-web/models';
import {
  FilterOptionValue,
  SearchResponse,
  SearchResult,
} from '@avenir-client-web/search';
import {
  searchAzUsersApi,
  searchCountApi,
  searchLearningSessionsApi,
  searchNewsApi,
  searchPeopleApi,
  searchProcessApi,
  searchWikiApi,
} from '@shared/constants/api.constants';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(
    private readonly httpService: HttpService,
    private readonly searchMapperService: SearchMapperService
  ) {}

  searchProcesses(
    options: RequestOption
  ): Observable<PagingResponse<SearchResult>> {
    const url = this.httpService.buildRequestUrl(searchProcessApi, options);

    return this.httpService.get<PagingResponse<SearchResponse>>(url).pipe(
      map(response => ({
        ...response,
        data: response.data.map(item =>
          this.searchMapperService.mapSearchResultResponse(
            item,
            FilterOptionValue.PROCESS
          )
        ),
      }))
    );
  }

  searchPeople(
    options: RequestOption
  ): Observable<PagingResponse<SearchResult>> {
    const url = this.httpService.buildRequestUrl(searchPeopleApi, options);

    return this.httpService.get<PagingResponse<ProfileDetail>>(url).pipe(
      map(response => ({
        ...response,
        data: response.data.map(item =>
          this.searchMapperService.mapProfileDetail(item)
        ),
      }))
    );
  }

  searchNews(options: RequestOption): Observable<PagingResponse<SearchResult>> {
    const url = this.httpService.buildRequestUrl(searchNewsApi, options);

    return this.httpService.get<PagingResponse<SearchResponse>>(url).pipe(
      map(response => ({
        ...response,
        data: response.data.map(item =>
          this.searchMapperService.mapSearchResultResponse(
            item,
            FilterOptionValue.NEWS
          )
        ),
      }))
    );
  }

  searchWiki(options: RequestOption): Observable<PagingResponse<SearchResult>> {
    const url = this.httpService.buildRequestUrl(searchWikiApi, options);

    return this.httpService.get<PagingResponse<SearchResponse>>(url).pipe(
      map(response => ({
        ...response,
        data: response.data.map(item =>
          this.searchMapperService.mapSearchResultResponse(
            item,
            FilterOptionValue.WIKI
          )
        ),
      }))
    );
  }

  searchAzUsers(
    requestOption: RequestOption
  ): Observable<PagingResponse<ProfileDetail>> {
    const url = this.httpService.buildRequestUrl(
      searchAzUsersApi,
      requestOption
    );

    return this.httpService.get<PagingResponse<ProfileDetail>>(url);
  }

  searchLearningSessions(
    options: RequestOption
  ): Observable<PagingResponse<SearchResult>> {
    const url = this.httpService.buildRequestUrl(
      searchLearningSessionsApi,
      options
    );

    return this.httpService.get<PagingResponse<SearchResponse>>(url).pipe(
      map(response => ({
        ...response,
        data: response.data.map(item =>
          this.searchMapperService.mapSearchResultResponse(
            item,
            FilterOptionValue.ELEARNING
          )
        ),
      }))
    );
  }

  getSearchCount(options: RequestOption): Observable<SearchCountsResponse> {
    const url = this.httpService.buildRequestUrl(searchCountApi, options);

    return this.httpService
      .get<SingleResponse<SearchCountsResponse>>(url)
      .pipe(map(response => response.data));
  }
}
