import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { ResizeObserverService } from '../services/resize-observer.service';

@Directive({
  selector: '[appResizeObserver]',
})
export class ResizeObserverDirective implements OnDestroy {
  constructor(
    private readonly el: ElementRef,
    private readonly resizeObserver: ResizeObserverService
  ) {
    this.resizeObserver.startObserving(this.el);
  }

  ngOnDestroy(): void {
    this.resizeObserver.stopObserving(this.el);
  }
}
