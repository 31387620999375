<div class="search-result">
  <div class="breadcrumb">
    <app-search-breadcrumb
      [filter]="searchResult?.type"
      [searchResult]="searchResult"
    >
    </app-search-breadcrumb>
  </div>
  <div
    class="search-result-detail cursor-pointer"
    appLinkActionHandler
    [appLinkActionHandler]="searchResult?.url"
    [keywords]="searchResult?.keywords"
    [description]="searchResult?.description"
    [id]="searchResult?.processStepId"
  >
    <a class="search-result-detail__link">
      <span class="search-result-detail__title my-3">{{
        searchResult?.heading ?? searchResult?.title
      }}</span>
    </a>

    <p class="search-result-detail__description">
      {{ searchResult?.description }}
    </p>
  </div>

  <div *ngIf="searchResult?.alternatives?.lenght !== 0" class="ml-4">
    <div
      *ngFor="let item of searchResult?.alternatives"
      class="search-result-detail cursor-pointer mt-3"
      appLinkActionHandler
      [appLinkActionHandler]="searchResult?.url"
      [keywords]="searchResult?.keywords"
      [description]="item?.description"
      [id]="item?.processStepId"
    >
      <a class="search-result-detail__link">
        <span class="search-result-detail__relevant my-3">{{
          item?.heading
        }}</span>
      </a>

      <p class="search-result-detail__description">
        {{ item?.description }}
      </p>
    </div>
  </div>
</div>
