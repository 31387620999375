import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-dialog-content-layout',
  templateUrl: './dialog-content-layout.component.html',
  styleUrls: ['./dialog-content-layout.component.scss'],
})
export class DialogContentLayoutComponent {
  @Input() actionButtonsTemplate: TemplateRef<unknown>;

  @Input() saveLabel = $localize`default.action.save`;

  @Input() cancelLabel = $localize`default.action.cancel`;

  @Output() confirmationCloseEvent = new EventEmitter<void>();

  @Output() closeEvent = new EventEmitter<void>();

  @Output() saveEvent = new EventEmitter<void>();

  @HostListener('document: click', ['$event'])
  click(event: Event): void {
    event.stopPropagation();
    const el = event.target as HTMLElement;

    if (el.classList.contains('p-dialog-mask')) {
      if (el.querySelectorAll('.confirmation-dialog').length)
        this.confirmationCloseEvent.emit();
      else this.closeEvent.emit();
    }
  }
}
