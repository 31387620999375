import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BaseControlComponent } from '@avenir-client-web/base-control';
import { PagingResponse, RequestOption } from '@avenir-client-web/models';
import {
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  merge,
  Observable,
  of,
  pairwise,
  scan,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs';
import {
  InfinitySelectConfig,
  RootComponent,
} from './base-infinity-select.models';

@Directive()
export class BaseInfinitySelect
  extends BaseControlComponent
  implements OnInit, OnDestroy
{
  @Input() infinityConfig: InfinitySelectConfig;

  @Input() itemSize = 30;

  @Input() styleClass: string;

  @Input() options: unknown[];

  @Input() optionLabel: string;

  @Input() optionValue: string;

  @Input() dataKey: string;

  @Input() emptyFilterMessage: string = $localize`default.noResultsFound`;

  options$: Observable<unknown>;

  filterChanges$ = new Subject<string>();

  rootComponent: RootComponent;

  wrapperClass = '';

  readonly compUnsubscribe$ = new Subject<void>();

  readonly resetOptions$ = new Subject<unknown[]>();

  constructor(
    private readonly scrollDispatcher: ScrollDispatcher,
    private readonly cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.initInfinityLoad();
  }

  ngOnDestroy(): void {
    this.compUnsubscribe$.next();
    this.compUnsubscribe$.complete();
    this.filterChanges$.complete();
    this.resetOptions$.complete();
  }

  setRootComponent(component: RootComponent): void {
    this.rootComponent = component;
  }

  setWrapperClass(className: string): void {
    this.wrapperClass = className;
  }

  onPanelHide(): void {
    if (!this.infinityConfig) return;

    this.resetOptions$.next(this.infinityConfig.defaultOptions || []);
    this.filterChanges$.next('');
  }

  private initInfinityLoad(): void {
    if (!this.infinityConfig) return;

    this.setDefaultInfinityConfig();
    this.handleInfinityLoad();
  }

  private setDefaultInfinityConfig(): void {
    this.infinityConfig.requestOption ||= {
      paging: {
        pageNumber: 1,
        pageSize: 10,
      },
      filter: {
        keyword: '',
      },
    };

    this.infinityConfig.filterChange ||= (
      option: RequestOption,
      filterValue: string
    ) => {
      option.filter.keyword = filterValue;
      option.paging.pageNumber = 1;
    };

    this.infinityConfig.nextPage ||= (option: RequestOption) =>
      option.paging.pageNumber++;

    this.infinityConfig.takeWhile ||= (response: PagingResponse<unknown>) =>
      response.hasNextPage;

    this.infinityConfig.mapData ||= (response: PagingResponse<unknown>) =>
      response.data;
  }

  private handleInfinityLoad(): void {
    // Override primeNG functions
    this.rootComponent.activateFilter = () => undefined;

    this.options$ = merge(
      of(this.infinityConfig.defaultOptions),
      this.resetOptions$,
      this.filterChanges$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map(value => value?.trim()),
        filter(value => !!value),
        tap(value =>
          this.infinityConfig.filterChange(
            this.infinityConfig.requestOption,
            value
          )
        ),
        takeUntil(this.compUnsubscribe$),
        switchMap(() =>
          this.scrollDispatcher.scrolled().pipe(
            filter(scrollable => {
              const scrollViewport = document.body
                .querySelector(`.${this.wrapperClass}`)
                .querySelector('cdk-virtual-scroll-viewport');

              return (
                (scrollable as CdkScrollable).getElementRef().nativeElement ===
                scrollViewport
              );
            }),
            map(scrollable =>
              (scrollable as CdkScrollable).measureScrollOffset('bottom')
            ),
            pairwise(),
            filter(([prev, curr]) => curr < prev && curr < this.itemSize * 10),
            startWith(null),
            exhaustMap(() =>
              this.infinityConfig.sourceStream$(
                this.infinityConfig.requestOption
              )
            ),
            tap(response =>
              this.infinityConfig.nextPage(
                this.infinityConfig.requestOption,
                response
              )
            ),
            takeWhile(
              response => this.infinityConfig.takeWhile(response),
              true
            ),
            map(response => this.infinityConfig.mapData(response)),
            scan(
              (acc, curr) => [...acc, ...curr],
              this.infinityConfig.defaultOptions || []
            ),
            tap(() => setTimeout(() => this.cd.detectChanges())),
            takeUntil(this.compUnsubscribe$)
          )
        )
      )
    );
  }
}
