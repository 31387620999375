import { TenantRole } from '@core/models/role.model';

export interface UserPermission {
  role: TenantRole;
  permissions: Permission[];
}

export enum Permission {
  NONE = 0,
  READ = 1,
  WRITE = 2,
  EDIT = 4,
  DELETE = 8,
  ADMIN = 16,
  MANAGER = 32,
  TICKET = 64,
  TICKET_MANAGEMENT = 128,
}
