import { Component, Input } from '@angular/core';
import { FilterOptionValue } from '../enums/search.enums';
import { SearchResult } from '../interfaces/search.interfaces';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent {
  @Input() searchResult: SearchResult;

  @Input() filter: FilterOptionValue;
}
