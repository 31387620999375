import { Component, OnInit } from '@angular/core';
import { CredentialsService } from '@core/authentication/credentials.service';
import { AppRootRoute } from '@core/enums/app-route.enum';
import { Permission } from '@core/services/permission.model';
import { PermissionService } from '@core/services/permission.service';
import { BaseComponent } from '@shared/base-component/base.component';
import { GenericInfo } from '../../models/generic-page.model';

@Component({
  selector: 'app-unauthorized-ticket-page',
  templateUrl: './unauthorized-ticket-page.component.html',
  styleUrls: ['./unauthorized-ticket-page.component.scss'],
})
export class UnauthorizedTicketPageComponent
  extends BaseComponent
  implements OnInit
{
  pageInfo: GenericInfo = {
    title: $localize`unauthorizedTicketPage.title`,
    description: $localize`unauthorizedTicketPage.description`,
    descriptionTitle: $localize`unauthorizedTicketPage.descriptionTitle`,
    redirectionBtns: [],
  };

  constructor(
    override readonly credentialsService: CredentialsService,
    override readonly permissionService: PermissionService
  ) {
    super(credentialsService, permissionService);
  }

  override ngOnInit(): void {
    this.renderByPermission();
  }

  private renderByPermission(): void {
    this.pageInfo.redirectionBtns = [
      {
        title: $localize`unauthorizedTicketPage.ticketOverviewBtn`,
        route: AppRootRoute.TICKETS,
      },
    ];

    if (this.permissionService.hasPermission(Permission.TICKET_MANAGEMENT))
      this.pageInfo.redirectionBtns.push({
        title: $localize`unauthorizedTicketPage.ticketManagementBtn`,
        route: AppRootRoute.ADMIN_TICKETS,
      });
  }
}
