import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { TicketFormKey } from '@app/ticket/enums/ticket.enums';
import { TicketService } from '@app/ticket/services/ticket.service';
import { FormErrorKey } from '@avenir-client-web/enums';
import { checkRequiredControls } from '@avenir-client-web/form-utils';
import { ErrorResponse } from '@avenir-client-web/models';
import { LeaveDialogService } from '@core/services/leave-dialog.service';
import { AppContentService } from '@core/services/static/app-content.service';
import { titleMaxLength } from '@shared/constants/field-validation.constants';
import { ContentFilterComponent } from '@shared/content-filter/content-filter.component';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, filter, Subject, takeUntil, tap, throwError } from 'rxjs';

@Component({
  selector: 'app-need-help',
  templateUrl: './need-help.component.html',
  styleUrls: ['./need-help.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NeedHelpComponent implements OnInit, OnDestroy {
  @ViewChild(ContentFilterComponent) contentFilter: ContentFilterComponent;

  formKey = TicketFormKey;

  needHelpForm: FormGroup;

  titleMaxLength = titleMaxLength;

  formErrorKey = FormErrorKey;

  private readonly compUnsubscribe$ = new Subject<void>();

  get contentCategoryControl(): AbstractControl {
    return this.needHelpForm?.controls[this.formKey.CONTENT_CATEGORY];
  }

  get contentTypeControl(): AbstractControl {
    return this.needHelpForm?.controls[this.formKey.CONTENT_TYPE];
  }

  get relatedContentControl(): AbstractControl {
    return this.needHelpForm?.controls[this.formKey.RELATED_CONTENT];
  }

  get titleControl(): AbstractControl {
    return this.needHelpForm?.controls[this.formKey.TITLE];
  }

  get contentControl(): AbstractControl {
    return this.needHelpForm?.controls[this.formKey.CONTENT];
  }

  get plainContentControl(): AbstractControl {
    return this.needHelpForm?.controls[this.formKey.PLAIN_CONTENT];
  }

  constructor(
    private readonly leaveDialogService: LeaveDialogService,
    private readonly dialogRef: DynamicDialogRef,
    private readonly fb: FormBuilder,
    private readonly ticketService: TicketService,
    private readonly messageService: MessageService,
    private readonly dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroyAllDialog();
    this.compUnsubscribe$.next();
    this.compUnsubscribe$.complete();
  }

  close(): void {
    if (!this.needHelpForm.dirty) {
      this.dialogRef.close();

      return;
    }

    const dialogRef = this.leaveDialogService.showLeaveConfirmationDialog();

    dialogRef.onClose
      .pipe(
        filter((isConfirm: boolean) => isConfirm),
        tap(() => this.dialogRef.close()),
        takeUntil(this.compUnsubscribe$)
      )
      .subscribe();
  }

  save(): void {
    this.checkFormErrors();

    if (this.needHelpForm.invalid) return;

    this.ticketService
      .createActionTicket(this.needHelpForm.getRawValue())
      .pipe(
        tap(() => {
          this.showSuccessMessage();

          this.dialogRef.close();
        }),
        takeUntil(this.compUnsubscribe$),
        catchError((error: ErrorResponse) => throwError(() => error))
      )
      .subscribe();
  }

  private initForm(): void {
    this.needHelpForm = this.fb.group({
      [this.formKey.CONTENT_CATEGORY]: [
        {
          id: 0,
        },
      ],
      [this.formKey.CONTENT_TYPE]: [0],
      [this.formKey.RELATED_CONTENT]: [
        {
          id: 0,
        },
      ],
      [this.formKey.TITLE]: [''],
      [this.formKey.CONTENT]: [''],
      [this.formKey.PLAIN_CONTENT]: [''],
    });
    this.relatedContentControl.disable();

    setTimeout(() => {
      this.contentFilter.subscribeFieldChanges();

      const { categoryId, contentType, relatedContentId } =
        AppContentService.getCurrentContent();

      if (categoryId) this.contentCategoryControl.setValue({ id: categoryId });

      if (contentType) this.contentTypeControl.setValue(contentType);

      if (relatedContentId)
        this.relatedContentControl.setValue({ id: relatedContentId });
    });
  }

  private checkFormErrors(): void {
    checkRequiredControls([this.titleControl, this.contentControl]);
  }

  private showSuccessMessage(): void {
    this.messageService.add({
      severity: 'success',
      summary: $localize`needHelp.message.createSuccess`,
    });
  }

  private destroyAllDialog(): void {
    this.dialogService.dialogComponentRefMap?.forEach(dialogRef =>
      dialogRef?.destroy()
    );
  }
}
