import { AbstractControl } from '@angular/forms';
import { FormErrorKey } from '@avenir-client-web/enums';
import { linkMaxLength } from '@shared/constants/field-validation.constants';

export function checkRequiredControls(controls: AbstractControl[]): void {
  controls.forEach(
    (control: AbstractControl) =>
      !control.value?.toString() &&
      control.setErrors({
        [FormErrorKey.REQUIRED]: true,
      })
  );
}

export function checkMaxLengthControl(
  control: AbstractControl,
  maxLength = linkMaxLength
): void {
  control.value?.length > maxLength &&
    control.setErrors({
      [FormErrorKey.MAX_LENGTH]: true,
    });
}
