import { Injectable } from '@angular/core';
import { HttpService } from '@avenir-client-web/http';
import { ListResponse } from '@avenir-client-web/models';
import { User } from '@core/models/profile.model';
import { userApi } from '@shared/constants/api.constants';
import { Observable, pluck } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private readonly httpService: HttpService) {}

  onSignIn(): Observable<void> {
    return this.httpService.post(`${userApi}/login-procedure`, {});
  }

  getUsersByRoles(roleIds: number[]): Observable<User[]> {
    return this.httpService
      .post<ListResponse<User>>(`${userApi}/by-roles`, roleIds)
      .pipe(pluck('data'));
  }
}
