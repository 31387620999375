import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  NavigationEnd,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { APP_CONFIG } from '@avenir-client-web/models';
import { CredentialsService } from '@core/authentication/credentials.service';
import { AppRootRoute } from '@core/enums/app-route.enum';
import { PermissionService } from '@core/services/permission.service';
import { filter, first, map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserFeatureGuard implements CanLoad, CanActivate {
  private readonly hiddenFeatures: AppRootRoute[] = [];

  constructor(
    @Inject(APP_CONFIG) readonly config: any,
    private readonly router: Router,
    private readonly credentialsService: CredentialsService,
    private readonly permissionService: PermissionService
  ) {
    if (!this.config.featureToggle.tickets)
      this.hiddenFeatures = [
        ...[
          AppRootRoute.TICKETS,
          AppRootRoute.ADMIN_TICKETS,
          AppRootRoute.NOTIFICATIONS,
        ],
      ];
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.validateUserPermissions(route);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return (
      this.validateUserPermissions(route) &&
      this.validateAccessibleFeature(route)
    );
  }

  private validateUserPermissions(
    route: Route | ActivatedRouteSnapshot
  ): Observable<boolean> {
    return this.credentialsService.credentialsChanges().pipe(
      filter(response => !!response),
      map(() => {
        if (!this.permissionService.checkScreenAccess(route.data.permission)) {
          this.router.navigate([AppRootRoute.ERRORS, AppRootRoute.FORBIDDEN]);

          return false;
        } else {
          this.router.events
            .pipe(
              filter(event => event instanceof NavigationEnd),
              tap(() => {
                this.permissionService.currentScreenPermission =
                  route.data.permission;
              })
            )
            .subscribe();

          return true;
        }
      }),
      first()
    );
  }

  private validateAccessibleFeature(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const notAllowed = this.hiddenFeatures.some(
        x => x === route.routeConfig.path
      );

      if (notAllowed)
        this.router.navigate([AppRootRoute.ERRORS, AppRootRoute.FORBIDDEN]);

      observer.next(!notAllowed);
      observer.complete();
    });
  }
}
