<div
  class="search-results"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollContainer]="'.main-content-wrapper .scrollable-wrapper'"
  [fromRoot]="true"
  (scrolled)="scrollDown.emit($event)"
>
  <div class="section-header" *ngIf="isAllTab && data?.length > 0">
    <div class="horizontal-line"></div>
    <h1>{{ option?.label }}</h1>
  </div>

  <ng-container *ngIf="data?.length; else noResultsFound">
    <app-search-result
      *ngFor="let item of data"
      [searchResult]="item"
    ></app-search-result>
  </ng-container>

  <ng-template #noResultsFound>
    <span *ngIf="!isAllTab" i18n>default.noResultsFound</span>
  </ng-template>
</div>

<div class="action" *ngIf="isAllTab && data?.length > 0">
  <div class="btn-item" *ngFor="let btn of showItemButtons">
    <app-button
      *ngIf="btn.visible"
      [label]="btn.label"
      [styleClass]="btn.styleClass"
      [piIcon]="btn.icon"
      [iconPos]="btn.iconPos"
      (clicked)="btn.show()"
    >
    </app-button>
  </div>
</div>
