import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SecurityContext,
  ViewEncapsulation,
} from '@angular/core';
import { ErrorCode, ErrorResponse } from '@avenir-client-web/models';
import { ProgressSpinnerService } from '@avenir-client-web/progress-spinner';
import { MessageService } from 'primeng/api';
import {
  catchError,
  finalize,
  map,
  Observable,
  Subject,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { FileExtension } from '../../enums/file-extension.enum';
import { FileResponseType } from '../../enums/file-response-type.enum';
import { FileService } from '../../services/file.service';
import { getFileExtension, shouldPreview } from '../../utils/file.utils';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FilePreviewComponent implements OnDestroy, OnInit {
  @Input() fileId: string;

  @Input() fileName: string;

  fileUrl$: Observable<string>;

  fileBlob: Blob;

  isPdfFile: boolean;

  securityContext = SecurityContext;

  hasError = false;

  private readonly compUnsubscribe$ = new Subject<void>();

  constructor(
    private readonly fileService: FileService,
    private readonly progressSpinnerService: ProgressSpinnerService,
    private readonly messageService: MessageService
  ) {}

  ngOnDestroy(): void {
    this.compUnsubscribe$.next();
    this.compUnsubscribe$.complete();
  }

  ngOnInit(): void {
    this.getFileUrl(this.fileId);
  }

  downloadFile(): void {
    this.fileBlob &&
      this.fileService.downloadFileByBlob(this.fileBlob, this.fileName);
  }

  private getFileUrl(fileId: string): void {
    if (!this.fileId || !this.fileName) {
      return;
    }

    this.progressSpinnerService.showProgressSpinner(true);

    this.fileUrl$ = this.fileService
      .getFileData(fileId, '*', FileResponseType.BLOB)
      .pipe(
        tap(data => (this.fileBlob = data as Blob)),
        map(data => {
          const blobData = data as Blob;
          const extension = getFileExtension(this.fileName);

          this.isPdfFile = extension === FileExtension.PDF;

          if (shouldPreview(extension)) {
            return URL.createObjectURL(blobData);
          }

          this.hasError = true;
          this.downloadFile();

          return undefined;
        }),
        catchError((error: ErrorResponse) => {
          error.errorCode === ErrorCode.FILE_NOT_EXIST &&
            this.showErrorMessage();
          this.fileBlob = undefined;
          this.hasError = true;

          return throwError(() => error);
        }),
        finalize(() => this.progressSpinnerService.showProgressSpinner(false)),
        takeUntil(this.compUnsubscribe$)
      );
  }

  private showErrorMessage(): void {
    this.messageService.add({
      severity: 'error',
      summary: $localize`filePreview.message.fileNotExist`,
    });
  }
}
