import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ActionTicketFormComponent } from '@app/ticket/components/action-ticket-form/action-ticket-form.component';
import { InfoTicketFormComponent } from '@app/ticket/components/info-ticket-form/info-ticket-form.component';
import { ticketTypeOptions } from '@app/ticket/constants/ticket.constants';
import { TicketFormKey, TicketType } from '@app/ticket/enums/ticket.enums';
import { TicketService } from '@app/ticket/services/ticket.service';
import {
  ComponentLoaderComponent,
  ComponentLoaderConfig,
} from '@avenir-client-web/component-loader';
import { checkRequiredControls } from '@avenir-client-web/form-utils';
import { LeaveDialogService } from '@core/services/leave-dialog.service';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  filter,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

@Component({
  selector: 'app-ticket-creation',
  templateUrl: './ticket-creation.component.html',
  styleUrls: ['./ticket-creation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TicketCreationComponent implements OnInit, OnDestroy {
  @ViewChild(ComponentLoaderComponent, { static: true })
  compLoader: ComponentLoaderComponent;

  formKey = TicketFormKey;

  ticketForm: FormGroup;

  typeOptions = ticketTypeOptions;

  compLoaderConfig: ComponentLoaderConfig;

  private readonly compUnsubscribe$ = new Subject<void>();

  get titleControl(): AbstractControl {
    return this.ticketForm?.controls[this.formKey.TITLE];
  }

  get contentControl(): AbstractControl {
    return this.ticketForm?.controls[this.formKey.CONTENT];
  }

  get typeControl(): AbstractControl {
    return this.ticketForm?.controls[this.formKey.TYPE];
  }

  constructor(
    private readonly leaveDialogService: LeaveDialogService,
    private readonly dialogRef: DynamicDialogRef,
    private readonly fb: FormBuilder,
    private readonly ticketService: TicketService,
    private readonly messageService: MessageService,
    private readonly dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.listenTypeChange();
  }

  ngOnDestroy(): void {
    this.destroyAllDialog();
    this.compUnsubscribe$.next();
    this.compUnsubscribe$.complete();
  }

  close(): void {
    if (!this.ticketForm.dirty) return this.dialogRef.close();

    this.leaveDialogService
      .showLeaveConfirmationDialog()
      .onClose.pipe(
        filter((isConfirm: boolean) => isConfirm),
        tap(() => this.dialogRef.close()),
        takeUntil(this.compUnsubscribe$)
      )
      .subscribe();
  }

  save(): void {
    this.checkFormErrors();

    if (this.ticketForm.invalid) return;

    const payload = { ...this.ticketForm.getRawValue() };
    let save$: Observable<void>;

    delete payload[this.formKey.TYPE];

    if (this.typeControl.value === TicketType.ACTION) {
      delete payload[this.formKey.MANAGED_BY];
      !payload.assignee?.aadId && delete payload.assignee;
      save$ = this.ticketService.createActionTicket(payload);
    } else {
      payload.infos = [
        {
          [this.formKey.TITLE]: payload[this.formKey.TITLE],
          [this.formKey.CONTENT]: payload[this.formKey.CONTENT],
          [this.formKey.LINK]: payload[this.formKey.LINK],
          language: {
            id: 1,
            name: 'de',
          },
        },
      ];
      delete payload[this.formKey.TITLE];
      delete payload[this.formKey.CONTENT];
      delete payload[this.formKey.LINK];
      save$ = this.ticketService.createInfoTicket(payload);
    }

    save$.pipe(takeUntil(this.compUnsubscribe$)).subscribe(() => {
      this.showSuccessMessage();
      this.dialogRef.close();
    });
  }

  private renderFormByType(type: TicketType): void {
    const config: ComponentLoaderConfig = {
      componentType: ActionTicketFormComponent,
      params: {
        formGroup: this.ticketForm,
      },
    };

    if (type === TicketType.INFO)
      config.componentType = InfoTicketFormComponent;

    this.compLoaderConfig = config;
  }

  private initForm(): void {
    this.ticketForm = this.fb.group({
      [this.formKey.TYPE]: [TicketType.ACTION],
      [this.formKey.TITLE]: [undefined],
      [this.formKey.CONTENT]: [undefined],
    });
  }

  private listenTypeChange(): void {
    this.typeControl?.valueChanges
      .pipe(
        startWith(TicketType.ACTION),
        tap(() => this.typeControl.markAsPristine()),
        switchMap(type => {
          if (!this.ticketForm.dirty) return of(type);

          return this.leaveDialogService
            .showLeaveConfirmationDialog()
            .onClose.pipe(
              tap(isConfirm => !isConfirm && this.toggleType(type)),
              filter(Boolean),
              map(() => type)
            );
        }),
        takeUntil(this.compUnsubscribe$)
      )
      .subscribe((type: TicketType) => {
        this.titleControl.reset();
        this.contentControl.reset();
        this.renderFormByType(type);
      });
  }

  private toggleType(currentType: TicketType): void {
    const targetType =
      currentType === TicketType.ACTION ? TicketType.INFO : TicketType.ACTION;

    this.typeControl.setValue(targetType, { emitEvent: false });
  }

  private checkFormErrors(): void {
    checkRequiredControls([this.titleControl, this.contentControl]);

    const { compInstance } = this.compLoader;

    if (compInstance instanceof InfoTicketFormComponent)
      compInstance.checkFormErrors();
  }

  private showSuccessMessage(): void {
    this.messageService.add({
      severity: 'success',
      summary: $localize`openTicket.message.createSuccess`,
    });
  }

  private destroyAllDialog(): void {
    this.dialogService.dialogComponentRefMap?.forEach(dialogRef =>
      dialogRef?.destroy()
    );
  }
}
