import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AzPaging, Paging, RequestOption } from '@avenir-client-web/models';
import { progressBar } from '@avenir-client-web/progress-bar';
import { progressSpinner } from '@avenir-client-web/progress-spinner';
import { Observable } from 'rxjs';
import { httpCacheKey } from '../constants/http-cache.constants';
import { HttpParameterEncoder } from '../utils/http-parameter-encoder';

@Injectable({ providedIn: 'root' })
export class HttpService {
  constructor(private readonly http: HttpClient) {}

  get<T>(url: string, options: unknown = {}): Observable<T> {
    if (options?.[httpCacheKey])
      options['headers'] = {
        ...(options['headers'] || {}),
        [httpCacheKey]: options[httpCacheKey].toString(),
      };

    this.setProgressBarContext(options);

    return this.http.get<T>(url, options);
  }

  post<T>(url: string, body: unknown, options: unknown = {}): Observable<T> {
    this.setProgressSpinnerContext(options);

    return this.http.post<T>(url, body, options);
  }

  patch<T>(url: string, body: unknown, options: unknown = {}): Observable<T> {
    this.setProgressSpinnerContext(options);

    return this.http.patch<T>(url, body, options);
  }

  put<T>(url: string, body: unknown, options: unknown = {}): Observable<T> {
    this.setProgressSpinnerContext(options);

    return this.http.put<T>(url, body, options);
  }

  delete<T>(url: string, options?: unknown): Observable<T> {
    return this.http.delete<T>(url, options);
  }

  buildRequestUrl(
    url: string,
    requestOption: RequestOption<Paging | AzPaging>
  ): string {
    let httpParams = new HttpParams({ encoder: new HttpParameterEncoder() });

    if (requestOption.paging) {
      for (const key in requestOption.paging) {
        httpParams = httpParams.append(key, requestOption.paging[key]);
      }
    }

    if (requestOption.getSortBy || requestOption.sortBy) {
      const sortBy =
        requestOption.sortBy?.length > 0
          ? requestOption.sortBy
          : requestOption.getSortBy();

      httpParams = httpParams.append('sortBy', sortBy);
    }

    if (requestOption.filter) {
      for (const key in requestOption.filter) {
        const filterValue = requestOption.filter[key];

        if (typeof filterValue !== 'object') {
          httpParams = httpParams.append(key, filterValue.toString());
        } else {
          const filters = [...(requestOption.filter[key] as Array<string>)];

          for (const item of filters) {
            httpParams = httpParams.append(key, item);
          }
        }
      }
    }

    return `${url}?${httpParams.toString()}`;
  }

  private setProgressBarContext(options: unknown): void {
    this.setHttpContext(options, new HttpContext().set(progressBar, true));
  }

  private setProgressSpinnerContext(options: unknown): void {
    this.setHttpContext(options, new HttpContext().set(progressSpinner, true));
  }

  private setHttpContext(options: unknown, context: HttpContext): void {
    (options as { context: HttpContext }).context ||= context;
  }
}
