import { InfinitySelectConfig } from '@avenir-client-web/base-infinity-select';
import { AzPaging, RequestOption } from '@avenir-client-web/models';

const defaultPageSize = 10;

export const azInfinityConfig: InfinitySelectConfig = {
  requestOption: {
    filter: {
      keyword: '',
    },
    paging: {
      pageSize: defaultPageSize,
      skipToken: '',
      sortBy: 'displayName',
    },
  } as RequestOption<AzPaging>,
  filterChange(requestOption: RequestOption<AzPaging>, filterValue: string) {
    requestOption.filter.keyword = filterValue;
    delete requestOption.paging.skipToken;
  },
  nextPage(requestOption: RequestOption<AzPaging>, { actionToken }) {
    requestOption.paging.skipToken = actionToken;
  },
  takeWhile({ actionToken }) {
    return actionToken;
  },
  mapData({ data }) {
    return data;
  },
  sourceStream$: undefined,
};
