import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { ProgressSpinnerService } from '../services/progress-spinner.service';

export const progressSpinner = new HttpContextToken<boolean>(() => false);

@Injectable()
export class ProgressSpinnerInterceptor implements HttpInterceptor {
  constructor(
    private readonly progressSpinnerService: ProgressSpinnerService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!req.context.get(progressSpinner)) return next.handle(req);

    this.progressSpinnerService.showProgressSpinner(true);

    return next
      .handle(req)
      .pipe(
        finalize(() => this.progressSpinnerService.showProgressSpinner(false))
      );
  }
}
