<app-dialog-content-layout
  (closeEvent)="cancel()"
  [saveLabel]="saveLabel"
  (saveEvent)="select()"
>
  <div #viewportWrapper class="upload-wrapper">
    <span class="title" i18n>uploadFile.title</span>
    <cdk-virtual-scroll-viewport class="images-grid" itemSize="140">
      <div
        *cdkVirtualFor="let items of itemSet; first as isFirst"
        class="image-row"
      >
        <ng-container
          *ngIf="isFirst"
          [ngTemplateOutlet]="uploadBtn"
        ></ng-container>

        <ng-container
          *ngFor="let image of items"
          [ngTemplateOutlet]="thumbnail"
          [ngTemplateOutletContext]="{ image: image }"
        ></ng-container>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</app-dialog-content-layout>

<ng-template #uploadBtn>
  <span
    #gridItem
    [style.visibility]="!isLoaded ? 'hidden' : ''"
    class="upload-btn"
  >
    <p-fileUpload
      (onSelect)="handleFileInput($event)"
      [auto]="true"
      [customUpload]="true"
      [accept]="acceptType"
      mode="basic"
      name="file"
      uploadIcon="'fa fa-plus'"
    ></p-fileUpload>
  </span>
</ng-template>

<ng-template #thumbnail let-thumbnail="image">
  <span
    *ngIf="thumbnail; else emptyElement"
    (click)="onSelectedImage(thumbnail)"
    [class.active]="selectedFile?.id === thumbnail.id"
    class="thumbnail"
  >
    <app-image
      [imageId]="thumbnail.id"
      [isSvg]="dialogConfig.data?.isSvg"
      [compressionType]="compressionType"
    ></app-image>
  </span>
</ng-template>

<ng-template #emptyElement>
  <div class="thumbnail"></div>
</ng-template>
