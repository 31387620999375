import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appMaxLength]',
})
export class MaxLengthDirective {
  @Input() appMaxLength: number;

  @HostListener('input', ['$event'])
  sliceMaxLength({ target }: { target: HTMLInputElement }): void {
    if (target.value?.length > this.appMaxLength) {
      target.value = target.value.slice(0, this.appMaxLength);
    }
  }
}
