/**
 * The character used to mark the start and end of a "block" in a `$localizer` tagged string.
 * A block can indicate metadata about the message or specify a name of a placeholder for a
 * substitution expressions.
 *
 * For example:
 *
 * ```ts
 * $localizer`Hello, ${title}:title:!`;
 * $localizer`:meaning|description@@id:source message text`;
 * ```
 */
export const BLOCK_MARKER = ':';

/**
 * The marker used to separate a message's "meaning" from its "description" in a metadata block.
 *
 * For example:
 *
 * ```ts
 * $localizer `:correct|Indicates that the user got the answer correct: Right!`;
 * $localizer `:movement|Button label for moving to the right: Right!`;
 * ```
 */
export const MEANING_SEPARATOR = '|';

/**
 * The marker used to separate a message's custom "id" from its "description" in a metadata block.
 *
 * For example:
 *
 * ```ts
 * $localizer `:A welcome message on the home page@@myApp-homepage-welcome: Welcome!`;
 * ```
 */
export const ID_SEPARATOR = '@@';

/**
 * The marker used to separate legacy message ids from the rest of a metadata block.
 *
 * For example:
 *
 * ```ts
 * $localizer `:@@custom-id␟2df64767cd895a8fabe3e18b94b5b6b6f9e2e3f0: Welcome!`;
 * ```
 *
 * Note that this character is the "symbol for the unit separator" (␟) not the "unit separator
 * character" itself, since that has no visual representation. See https://graphemica.com/%E2%90%9F.
 *
 * Here is some background for the original "unit separator character":
 * https://stackoverflow.com/questions/8695118/whats-the-file-group-record-unit-separator-control-characters-and-its-usage
 */
export const LEGACY_ID_INDICATOR = '\u241F';
