import { HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { httpCacheKey } from '../constants/http-cache.constants';
import { CacheTimer } from '../utils/cache-timer';

export interface HttpCacheResponse extends HttpResponse<unknown> {
  timer: CacheTimer;
}

export interface HttpCacheConfig {
  /**
   * Http cache item will be expired in number of minutes
   */
  expiredIn: number;
}

export interface HttpCacheRequest extends HttpRequest<unknown> {
  headers: HttpHeaders & { [httpCacheKey]: string };
}
