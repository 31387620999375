import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GenericPageRoutingModule } from '@app/generic-page/generic-page-routing.module';
import { ImageModule } from '@avenir-client-web/image';
import { SafePipeModule } from '@avenir-client-web/safe-pipe';
import { TextReplaceModule } from '@avenir-client-web/text-replace';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NavigationBtnComponent } from './components/navigation-btn/navigation-btn.component';
import { ForbiddenPageComponent } from './containers/forbidden-page/forbidden-page.component';
import { UnauthorizedTicketPageComponent } from './containers/unauthorized-ticket-page/unauthorized-ticket-page.component';

@NgModule({
  declarations: [
    NavigationBtnComponent,
    ForbiddenPageComponent,
    UnauthorizedTicketPageComponent,
  ],
  imports: [
    CommonModule,
    ImageModule,
    GenericPageRoutingModule,
    SafePipeModule,
    AngularSvgIconModule,
    TextReplaceModule,
  ],
})
export class GenericPageModule {}
