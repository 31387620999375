export const notificationType = {
  ticket: {
    action: {
      create: 'createTicket',
      createBySelf: 'createTicketForCreator',
      resolve: 'resolveTicket',
      update: 'updateTicket',
      chat: 'createTicketChat',
    },
    info: {
      create: 'createInformationTicket',
    },
  },
  external: {
    easylearn: {
      general: 'generalEasylearn',
    },
  },
};
