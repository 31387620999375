import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@avenir-client-web/button';
import { DialogContentLayoutModule } from '@avenir-client-web/dialog-content-layout';
import { BackwardConfirmationComponent } from './backward-confirmation.component';

@NgModule({
  declarations: [BackwardConfirmationComponent],
  imports: [CommonModule, ButtonModule, DialogContentLayoutModule],
  exports: [BackwardConfirmationComponent],
})
export class BackwardConfirmationModule {}
