<div class="shell-wrapper" #shellWrapper appResizeObserver>
  <app-sidebar></app-sidebar>
  <div class="main-content-wrapper">
    <app-header></app-header>
    <div
      #scrollableWrapper
      cdkScrollable
      class="scrollable-wrapper custom-scrollbar"
    >
      <div class="content">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
  <app-mail-to-button
    (needHelp)="onClickNeedHelp()"
    i18n-buttonTitle
    *ngIf="shellService.needHelpVisibility$ | async"
    [mailAddress]="appSettings.defaultEmail"
    buttonTitle="needHelp.button.tooltip"
    [isOpenTicket]="featureToggle.tickets"
  ></app-mail-to-button>
</div>
