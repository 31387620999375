<div class="field">
  <div class="flex align-items-center justify-content-between">
    <label *ngIf="label" class="block" [ngClass]="{ required: hasAsterisk }"
      >{{ label }}
    </label>

    <div class="field-length" *ngIf="maxLength">
      {{ value?.length ?? 0 }}/{{ maxLength }}
    </div>
  </div>
  <input
    #input
    [type]="type"
    [disabled]="disabled"
    pInputText
    [attr.maxLength]="maxLength"
    [placeholder]="placeholder ?? ''"
    class="form-control"
    (input)="onValueChange($event.target.value)"
    (blur)="onBlur()"
    [(ngModel)]="value"
    [appMaxLength]="maxLength"
  />
  <ng-content></ng-content>
</div>
