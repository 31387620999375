import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from '../../../base-control/base-control.component';

type InputType = 'text' | 'number' | 'password' | 'email';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent extends BaseControlComponent<string> {
  @Input() maxLength: number;

  @Input() type: InputType = 'text';

  @Input() autoTrim = true;

  @ViewChild('input', { static: true }) input: ElementRef;

  onBlur(): void {
    if (this.autoTrim) {
      this.onValueChange(this.value?.trim());
    }
  }
}
