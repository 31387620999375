<div class="field">
  <label *ngIf="label" class="block" [ngClass]="{ required: hasAsterisk }">{{
    label
  }}</label>
  <p-multiSelect
    [options]="options"
    [filter]="false"
    [showHeader]="false"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [placeholder]="placeholder"
    i18n-emptyMessage
    emptyMessage="default.dropdown.empty"
    dropdownIcon="fa-solid fa-angle-down"
    [styleClass]="styleClass"
    (onChange)="onValueChange($event.value)"
    [(ngModel)]="value"
    [virtualScroll]="virtualScroll"
    itemSize="30"
  >
  </p-multiSelect>
  <ng-content></ng-content>
</div>
