import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, BaseAppConfig } from '@avenir-client-web/models';
import { StorageService } from '@avenir-client-web/storage';

export const LOCALE = 'LOCALE';

@Injectable({
  providedIn: 'root',
})
export class PreferredLanguageService {
  constructor(
    private readonly storageService: StorageService,
    @Inject(APP_CONFIG) private readonly config: BaseAppConfig
  ) {}

  getPreferredLocale(): string {
    return this.storageService.get(LOCALE) ?? this.config.defaultLanguage;
  }

  setPreferredLocale(locale: string): void {
    this.storageService.set(LOCALE, locale);
  }
}
