export class CacheTimer {
  private expiredAt: number;

  constructor(private readonly expiredIn: number) {
    this.setExpiredAt();
  }

  get isExpired(): boolean {
    return this.remainingTime <= 0;
  }

  private get remainingTime(): number {
    return this.expiredAt - new Date().getTime();
  }

  private setExpiredAt(): void {
    this.expiredAt = new Date().getTime() + this.expiredIn * 60 * 1000;
  }
}
