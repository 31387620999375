import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Editor } from 'primeng/editor';
import { editorDefaultFormats } from '../../../../common/constants/index';
import { BaseControlComponent } from '../../../base-control/base-control.component';

interface TextChangeEvent {
  htmlValue: string;
  textValue: string;
}

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true,
    },
  ],
})
export class EditorComponent
  extends BaseControlComponent
  implements AfterViewInit
{
  @ViewChild(Editor) editor: Editor;

  @Input() formats?: string[] = editorDefaultFormats;

  @Output() textChanged = new EventEmitter<TextChangeEvent>();

  ngAfterViewInit(): void {
    this.addWrapperForEditor();
  }

  private addWrapperForEditor(): void {
    const editorEl =
      this.editor.el.nativeElement.getElementsByClassName('ql-blank')[0];

    if (editorEl) {
      const editorWrapper = document.createElement('div');

      editorWrapper.className = 'editor-wrapper';

      editorEl.parentNode.appendChild(editorWrapper);

      editorWrapper.appendChild(editorEl);
    }
  }

  onTextChange({ htmlValue, textValue }: TextChangeEvent): void {
    const hasImgTag = htmlValue?.includes('img');

    super.onValueChange(hasImgTag || textValue ? htmlValue : '');
    this.textChanged.emit({ htmlValue, textValue });
  }
}
