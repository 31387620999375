import { RelatedContentType } from '@app/content-category/models/content-category.models';
import {
  TicketAssignmentFormAction,
  TicketAssignmentFormKey,
  TicketPriority,
  TicketStatus,
  TicketType,
} from '@app/ticket/enums/ticket.enums';
import { RadioButtonOption } from '@avenir-client-web/radio-button';
import { NONE_LABEL } from '@shared/constants/label.constants';
import { SelectItem } from 'primeng/api';

export const ticketStatusList: SelectItem<TicketStatus>[] = [
  {
    label: $localize`ticket.status.new`,
    value: TicketStatus.NEW,
    disabled: true,
  },
  {
    label: $localize`ticket.status.active`,
    value: TicketStatus.ACTIVE,
  },
  {
    label: $localize`ticket.status.resolved`,
    value: TicketStatus.RESOLVED,
  },
];

export const ticketPriorityList: SelectItem<TicketPriority>[] = [
  {
    label: $localize`ticket.priority.low`,
    value: TicketPriority.LOW,
  },
  {
    label: $localize`ticket.priority.medium`,
    value: TicketPriority.MEDIUM,
  },
  {
    label: $localize`ticket.priority.high`,
    value: TicketPriority.HIGH,
  },
];

export const contentTypeList = [
  {
    value: RelatedContentType.NONE,
    label: NONE_LABEL,
  },
  {
    value: RelatedContentType.NEWS,
    label: $localize`tickets.contentType.news`,
  },
  {
    value: RelatedContentType.PROCESS,
    label: $localize`tickets.contentType.process`,
  },
  // This will be used later
  // {
  //   value: RelatedContentType.WIKI_INDEX,
  //   label: $localize`tickets.contentType.wikiIndex`,
  // },
  {
    value: RelatedContentType.WIKI_DETAIL,
    label: $localize`tickets.contentType.wikiDetail`,
  },
];

export const ticketAssignmentFormItems = {
  ...TicketAssignmentFormKey,
  ...TicketAssignmentFormAction,
};

export const ticketTypeOptions: RadioButtonOption<TicketType>[] = [
  {
    label: $localize`ticketType.action`,
    value: TicketType.ACTION,
  },
  {
    label: $localize`ticketType.info`,
    value: TicketType.INFO,
  },
];
