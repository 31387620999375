import { BLOCK_MARKER } from '../models/constants';
import { LocalizationFn } from '../models/translation.model';
import { findEndOfBlock } from './string.function';

export function translate(messageKey: string): string {
  if (!messageKey) return undefined;

  const parsedTemplate = Object.assign([messageKey], {
    raw: [messageKey],
  }) as unknown as TemplateStringsArray;
  const translatedMsg = $localizer(parsedTemplate, []);

  return translatedMsg?.length > 0 ? translatedMsg : messageKey;
}

export const $localizer: LocalizationFn = function (
  messageParts: TemplateStringsArray,
  ...expressions: readonly unknown[]
) {
  if ($localizer.translate) {
    // Don't use array expansion here to avoid the compiler adding `__read()` helper unnecessarily.
    const translation = $localizer.translate(messageParts, expressions);

    messageParts = translation[0];
    expressions = translation[1];
  }
  let message = stripBlock(messageParts[0], messageParts.raw[0]);

  for (let i = 1; i < messageParts.length; i++) {
    message +=
      expressions[i - 1] + stripBlock(messageParts[i], messageParts.raw[i]);
  }

  return message;
};

/**
 * Strip a delimited "block" from the start of the `messagePart`, if it is found.
 *
 * If a marker character (:) actually appears in the content at the start of a tagged string or
 * after a substitution expression, where a block has not been provided the character must be
 * escaped with a backslash, `\:`. This function checks for this by looking at the `raw`
 * messagePart, which should still contain the backslash.
 *
 * @param messagePart The cooked message part to process.
 * @param rawMessagePart The raw message part to check.
 * @returns the message part with the placeholder name stripped, if found.
 * @throws an error if the block is unterminated
 */
function stripBlock(messagePart: string, rawMessagePart: string): string {
  return rawMessagePart.charAt(0) === BLOCK_MARKER
    ? messagePart.substring(findEndOfBlock(messagePart, rawMessagePart) + 1)
    : messagePart;
}
