<app-dialog-content-layout
  class="need-help-container"
  (closeEvent)="close()"
  (saveEvent)="save()"
>
  <span class="title" i18n>needHelp.createTitle</span>
  <form [formGroup]="needHelpForm" class="form-group">
    <app-content-filter [contentFilterForm]="needHelpForm"></app-content-filter>
    <app-input
      class="ticket-title"
      [formControlName]="formKey.TITLE"
      i18n-label
      label="needHelp.ticketTitle"
      i18n-placeholder
      placeholder="needHelp.ticketTitle.placeholder"
      [maxLength]="titleMaxLength"
      [hasAsterisk]="true"
    >
      <small
        *ngIf="titleControl?.hasError(formErrorKey.REQUIRED)"
        class="p-error block"
        i18n
        >default.error.required
      </small>
    </app-input>

    <app-editor
      class="ticket-content"
      [formControlName]="formKey.CONTENT"
      i18n-placeholder
      placeholder="needHelp.ticketContent.placeholder"
      i18n-label
      label="needHelp.ticketContent"
      [hasAsterisk]="true"
      (textChanged)="plainContentControl?.setValue($event.textValue)"
    >
      <small
        *ngIf="contentControl?.hasError(formErrorKey.REQUIRED)"
        class="p-error block"
        i18n
        >default.error.required
      </small>
    </app-editor>
  </form>
</app-dialog-content-layout>
