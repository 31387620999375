import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@avenir-client-web/models';
import { CredentialsService } from '@core/authentication/credentials.service';
import { AppConfig } from '@core/models/config.models';
import { TenantRole } from '@core/models/role.model';
import { Observable } from 'rxjs';

@Injectable()
export class PayloadHeaderInterceptor implements HttpInterceptor {
  constructor(
    @Inject(APP_CONFIG) private readonly config: AppConfig,
    private readonly credentialsService: CredentialsService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const languageCode: string = this.config.defaultLanguage;

    const role: TenantRole = this.credentialsService.getCredentials()?.role;

    if (!languageCode || !role || !role.id) {
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        Role: role.id.toString(),
        Language: languageCode,
      },
    });

    return next.handle(request);
  }
}
