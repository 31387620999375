import { Injectable } from '@angular/core';
import { HttpService } from '@avenir-client-web/http';
import { SingleResponse } from '@avenir-client-web/models';
import { CredentialsService } from '@core/authentication/credentials.service';
import { TenantRole } from '@core/models/role.model';
import { Permission, UserPermission } from '@core/services/permission.model';
import { setCurrentRoleApi } from '@shared/constants/api.constants';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  Subject,
  tap,
  withLatestFrom,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private readonly permissionsApi = `permissions`;

  userPermissions: UserPermission[];

  userPermissions$: BehaviorSubject<UserPermission[]> = new BehaviorSubject(
    null
  );

  roleDummyChanges$ = new Subject<void>();

  currentScreenPermission: number;

  constructor(
    private readonly httpService: HttpService,
    private readonly credentialsService: CredentialsService,
    private readonly ngxPermissionsService: NgxPermissionsService
  ) {}

  hasPermission(permission: Permission): boolean {
    const {
      role: { id },
    } = this.credentialsService.getCredentials();

    return this.userPermissions
      .find(item => item.role.id === id)
      .permissions.includes(permission);
  }

  setCurrentRole$(roleId: number): Observable<void> {
    return this.httpService.post(`${setCurrentRoleApi}/${roleId}`, null);
  }

  setCurrentRoleDummy(roleId: number): Observable<void> {
    return this.httpService.put(`profile/set-current-role/${roleId}`, null);
  }

  setUserRole(role: TenantRole): Observable<void> {
    return this.setCurrentRole$(role.id).pipe(
      tap(() => {
        const credential = {
          ...this.credentialsService.getCredentials(),
          role,
        };

        this.credentialsService.setCredentials(credential);
        this.credentialsService.onRoleSwitched$.next();
      })
    );
  }

  getUserPermissions(): Observable<UserPermission[]> {
    return this.httpService
      .get<SingleResponse<UserPermission[]>>(this.permissionsApi)
      .pipe(
        map(({ data }) => {
          if (data?.length >= 0) {
            this.userPermissions = data;
            this.userPermissions$.next(data);
          }

          return data;
        })
      );
  }

  checkScreenAccess(screenPermission: Permission): boolean {
    return this.userPermissions?.some(
      userPermission =>
        userPermission.role.id ===
          this.credentialsService.getCredentials().role.id &&
        userPermission.permissions.includes(screenPermission)
    );
  }

  checkCurrentScreenAccess(): boolean {
    return this.checkScreenAccess(this.currentScreenPermission);
  }

  loadPermissions(): Observable<NgxPermissionsObject> {
    return this.credentialsService.credentialsChanges().pipe(
      withLatestFrom(this.userPermissions$),
      filter(
        ([credential, userPermissions]) => !!credential && !!userPermissions
      ),
      tap(([credential, userPermission]) => {
        const permission = userPermission.find(
          item => item.role.id === credential.role.id
        );

        this.ngxPermissionsService.loadPermissions(
          permission.permissions.map(String)
        );
      }),
      map(() => this.ngxPermissionsService.getPermissions())
    );
  }
}
