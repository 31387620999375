import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GenericPageModule } from '@app/generic-page/generic-page.module';
import { AvatarModule } from '@avenir-client-web/avatar';
import { ButtonModule } from '@avenir-client-web/button';
import { DialogContentLayoutModule } from '@avenir-client-web/dialog-content-layout';
import { DropdownModule } from '@avenir-client-web/dropdown';
import { EditorModule } from '@avenir-client-web/editor';
import { FooterModule } from '@avenir-client-web/footer';
import { InputModule } from '@avenir-client-web/input';
import { MailToButtonModule } from '@avenir-client-web/mail-to-button';
import { ResizeObserverModule } from '@avenir-client-web/resize-observer';
import { SearchModule } from '@avenir-client-web/search';
import { ContentFilterModule } from '@shared/content-filter/content-filter.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SidebarModule } from 'primeng/sidebar';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { HeaderProfileComponent } from './components/header-profile/header-profile.component';
import { NeedHelpComponent } from './components/need-help/need-help.component';
import { SidebarItemComponent } from './components/sidebar-item/sidebar-item.component';
import { HeaderComponent } from './containers/header/header.component';
import { SideBarComponent } from './containers/sidebar/sidebar.component';
import { ShellComponent } from './shell.component';

@NgModule({
  declarations: [
    ShellComponent,
    SideBarComponent,
    SidebarItemComponent,
    HeaderComponent,
    HeaderProfileComponent,
    NeedHelpComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxPermissionsModule.forChild(),
    SearchModule,
    AvatarModule,
    TieredMenuModule,
    SidebarModule,
    FooterModule,
    MailToButtonModule,
    GenericPageModule,
    ScrollingModule,
    AngularSvgIconModule,
    ResizeObserverModule,
    ContentFilterModule,
    ReactiveFormsModule,
    DialogContentLayoutModule,
    ButtonModule,
    InputModule,
    EditorModule,
    DropdownModule,
  ],
})
export class ShellModule {}
