import { Component, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { ProgressBarService } from '../services/progress-bar.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgressBarComponent {
  isShowProgressBar$: Observable<boolean>;

  constructor(private readonly progressBarService: ProgressBarService) {
    this.isShowProgressBar$ = this.progressBarService.isShowProgressBar$;
  }
}
