/* eslint-disable @typescript-eslint/no-explicit-any */
export * from './lib/localizer.module';
export * from './localizer';

import { ɵ$localizer, ɵLocalizationFn } from './localizer';

// `declare global` allows us to escape the current module and place types on the global namespace
declare global {
  /**
   * **NOTE: This is a customized library that can run in `run-time` mode, in parallel with '@angular/localize' that runs in `compile-time` mode.**
   *
   * Tag a template literal string for localization.
   *
   * For example:
   *
   * ```ts
   * $localizer `some string to localize`
   * ```
   *
   * **Providing meaning and description**
   *
   * You can optionally specify one or more of `meaning` and `description` for a localized
   * string by pre-pending it with a colon delimited block of the form:
   *
   * ```ts
   * $localizer`:meaning|description`;
   *
   * $localizer`:meaning|:source message text`;
   * $localizer`:description:source message text`;
   * ```
   *
   * This format is the same as that used for `i18n` markers in Angular templates. See the
   * [Angular i18n guide](guide/i18n-common-prepare#mark-text-in-component-template).
   *
   * **Naming placeholders**
   *
   * If the template literal string contains expressions, then the expressions will be automatically
   * associated with placeholder names for you.
   *
   * For example:
   *
   * ```ts
   * $localizer `Hi ${name}! There are ${items.length} items.`;
   * ```
   *
   * will generate a message-source of `Hi {$PH}! There are {$PH_1} items`.
   *
   * The recommended practice is to name the placeholder associated with each expression though.
   *
   * Do this by providing the placeholder name wrapped in `:` characters directly after the
   * expression. These placeholder names are stripped out of the rendered localized string.
   *
   * For example, to name the `items.length` expression placeholder `itemCount` you write:
   *
   * ```ts
   * $localizer `There are ${items.length}:itemCount: items`;
   * ```
   *
   * **Escaping colon markers**
   *
   * If you need to use a `:` character directly at the start of a tagged string that has no
   * metadata block, or directly after a substitution expression that has no name you must escape
   * the `:` by preceding it with a backslash:
   *
   * For example:
   *
   * ```ts
   * // message has a metadata block so no need to escape colon
   * $localizer `:some description::this message starts with a colon (:)`;
   * // no metadata block so the colon must be escaped
   * $localizer `\:this message starts with a colon (:)`;
   * ```
   *
   * ```ts
   * // named substitution so no need to escape colon
   * $localizer `${label}:label:: ${}`
   * // anonymous substitution so colon must be escaped
   * $localizer `${label}\: ${}`
   * ```
   *
   * **Processing localized strings:**
   *
   * There are three scenarios:
   *
   * * **compile-time inlining**: the `$localizer` tag is transformed at compile time by a
   * transpiler, removing the tag and replacing the template literal string with a translated
   * literal string from a collection of translations provided to the transpilation tool.
   *
   * * **run-time evaluation**: the `$localizer` tag is a run-time function that replaces and
   * reorders the parts (static strings and expressions) of the template literal string with strings
   * from a collection of translations loaded at run-time.
   *
   * * **pass-through evaluation**: the `$localizer` tag is a run-time function that simply evaluates
   * the original template literal string without applying any translations to the parts. This
   * version is used during development or where there is no need to translate the localized
   * template literals.
   *
   * @param messageParts a collection of the static parts of the template string.
   * @param expressions a collection of the values of each placeholder in the template string.
   * @returns the translated string, with the `messageParts` and `expressions` interleaved together.
   */
  const $localizer: ɵLocalizationFn;
}

(globalThis as any).$localizer = ɵ$localizer;
