import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ButtonModule } from 'primeng/button';
import { ToastModule as PToastModule } from 'primeng/toast';
import { IconByTypePipe } from './icon-by-type.pipe';
import { ToastComponent } from './toast.component';

@NgModule({
  imports: [
    CommonModule,
    PToastModule,
    ButtonModule,
    AngularSvgIconModule,
    RouterModule,
  ],
  declarations: [ToastComponent, IconByTypePipe],
  exports: [ToastComponent],
})
export class ToastModule {}
