import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { FilterOptionValue } from '../enums/search.enums';
import { SearchResult } from '../interfaces/search.interfaces';

@Component({
  selector: 'app-search-breadcrumb',
  templateUrl: './search-breadcrumb.component.html',
  styleUrls: ['./search-breadcrumb.component.scss'],
})
export class SearchBreadcrumbComponent implements OnInit {
  @Input() searchResult: SearchResult;

  @Input() filter: FilterOptionValue;

  breadcrumbs: MenuItem[] = [];

  ngOnInit(): void {
    this.buildBreadcrumbBaseOnFilterOption();
  }

  private buildBreadcrumbBaseOnFilterOption(): void {
    if (!this.searchResult) return;

    const { label, url } = this.searchResult.breadcrumb;

    switch (this.filter) {
      case FilterOptionValue.WIKI:
      case FilterOptionValue.NEWS:
        this.breadcrumbs = [
          {
            label,
            url,
          },
          {
            label: this.searchResult.contentCategory?.name,
          },
        ];
        break;
      case FilterOptionValue.PROCESS:
        this.breadcrumbs = [
          {
            label: this.searchResult.contentCategory?.name,
            url: `${url}/${this.searchResult.contentCategory?.id}`,
          },
        ];
        break;
      case FilterOptionValue.PEOPLE:
        this.breadcrumbs = [
          {
            label,
          },
        ];
        break;
      case FilterOptionValue.ELEARNING:
        this.breadcrumbs = [
          {
            label,
            url,
          },
        ];
        break;
    }

    this.breadcrumbs.push({
      label: this.searchResult.title,
    });
  }
}
