import { SelectItem } from 'primeng/api';
import { Status } from '../enums/status.enum';

export const statusList: SelectItem<Status>[] = [
  {
    value: Status.PUBLISHED,
    label: $localize`default.status.published`,
  },
  {
    value: Status.HIDDEN,
    label: $localize`default.status.hidden`,
  },
];
