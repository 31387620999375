import { Injectable, SecurityContext } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ProfileDetail } from '@app/profile/models/profile.models';
import { FileResponseType } from '@avenir-client-web/file';
import { httpCacheKey, HttpService } from '@avenir-client-web/http';
import { ImageType } from '@avenir-client-web/image';
import { SingleResponse } from '@avenir-client-web/models';
import { SanitizerPipe } from '@avenir-client-web/sanitizer';
import { StorageService } from '@avenir-client-web/storage';
import { User } from '@core/models/profile.model';
import { profileApi } from '@shared/constants/api.constants';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  profile: User;

  userActiveKey = 'isCurrentUserActive';

  constructor(
    private readonly httpService: HttpService,
    private readonly storage: StorageService,
    private readonly sanitizer: SanitizerPipe
  ) {}

  getUserActiveState(): boolean {
    try {
      return JSON.parse(this.storage.get(this.userActiveKey));
    } catch {
      return false;
    }
  }

  setUserActiveState(state: boolean): void {
    this.storage.set(this.userActiveKey, state);
  }

  onLogout(): void {
    this.storage.remove(this.userActiveKey);
  }

  getProfile(): Observable<User> {
    return this.httpService
      .get<SingleResponse<User>>(`${profileApi}/lean`)
      .pipe(map(({ data }) => (this.profile = data)));
  }

  getAvatar(aadId: string): Observable<Blob> {
    return this.httpService.get<Blob>(`${profileApi}/${aadId}/photo`, {
      observe: 'body',
      responseType: FileResponseType.BLOB,
      [httpCacheKey]: true,
    });
  }

  getAvatarUrl(aadId: string): Observable<SafeUrl> {
    return new Observable(observer => {
      this.getAvatar(aadId)
        .pipe(catchError(() => of(' ')))
        .subscribe(data => {
          if (!data) {
            observer.next();
            observer.complete();

            return;
          }

          const blob = new Blob([data], {
            type: ImageType.PNG,
          });
          const reader = new FileReader();

          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            observer.next(
              this.sanitizer.transform(
                reader.result as string,
                SecurityContext.URL
              )
            );
            observer.complete();
          };
        });
    });
  }

  mapResponsiblePersons(
    profileDetails: ProfileDetail[],
    isSorted?: boolean
  ): ProfileDetail[] {
    if (isSorted) {
      profileDetails = profileDetails.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    }

    return profileDetails?.map(profile => {
      profile.avatar$ = this.getAvatarUrl(profile.aadId);

      return profile;
    });
  }
}
