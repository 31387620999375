import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';

@NgModule({
  imports: [CommonModule, BreadcrumbModule],
  declarations: [BreadcrumbComponent],
  exports: [BreadcrumbComponent],
})
export class AppBreadcrumbModule {}
