import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-mail-to-button',
  templateUrl: './mail-to-button.component.html',
  styleUrls: ['./mail-to-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MailToButtonComponent {
  @Input() mailAddress: string;

  @Input() buttonTitle: string;

  @Input() isOpenTicket = true;

  @Output() needHelp = new EventEmitter<void>();

  onClickNeedHelp(event: PointerEvent): void {
    if (this.isOpenTicket) {
      this.needHelp.next();

      return;
    }

    event.stopPropagation();
    window.location.href = `mailto:${this.mailAddress}`;
  }
}
