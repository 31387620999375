import { Inject, Injectable } from '@angular/core';
import {
  MenuGroups,
  MenuItemResponse,
  MenuResponse,
} from '@app/shell/models/sidebar.models';
import { translate } from '@avenir-client-web/localizer';
import { APP_CONFIG } from '@avenir-client-web/models';
import { menu } from '@core/constants/menu-items.constant';
import { AppRootRoute } from '@core/enums/app-route.enum';
import { SidebarItemKey } from '@core/enums/sidebar-item.enum';
import { Permission } from '@core/services/permission.model';
import { MenuItem } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class SidebarMapperService {
  private readonly hiddenFeatures: SidebarItemKey[] = [];

  constructor(@Inject(APP_CONFIG) readonly config: any) {
    if (!this.config.featureToggle.tickets)
      this.hiddenFeatures = [
        ...[SidebarItemKey.TICKET_MANAGEMENT, SidebarItemKey.TICKET_OVERVIEW],
      ];
  }

  mapMenuResponse(sidebarItems: MenuResponse): MenuGroups {
    if (!sidebarItems) {
      return { topMenu: [], processMenu: [], adminMenu: [] };
    }
    const mapMenuItems = (items: MenuItemResponse[]): MenuItem[] =>
      this.sortMenuItemResponse(items)
        .filter(menuItem => !this.hiddenFeatures.includes(menuItem.key))
        .map(({ key, id }) => {
          const menuItem = menu[key] ?? {
            label: key,
            url: `/${AppRootRoute.CONTENT_CATEGORIES}/${id}`,
            state: { permission: Permission.READ },
          };

          if (menuItem.translationKey?.length > 0 && !menuItem.label) {
            menuItem.label = translate(menuItem.translationKey);
          }

          return menuItem;
        })
        .filter(Boolean);

    return {
      topMenu: mapMenuItems(sidebarItems.user),
      processMenu: mapMenuItems(sidebarItems.contentCategory),
      adminMenu: mapMenuItems(sidebarItems.admin),
    };
  }

  private sortMenuItemResponse(items: MenuItemResponse[]): MenuItemResponse[] {
    return items.sort(
      (a: MenuItemResponse, b: MenuItemResponse) => a.order - b.order
    );
  }
}
