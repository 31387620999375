import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  catchError,
  filter,
  finalize,
  Observable,
  Subject,
  tap,
  throwError,
} from 'rxjs';

@Injectable()
export class ConcurrentIdenticalRequestInterceptor implements HttpInterceptor {
  private readonly pendingRequests: Map<string, Subject<HttpEvent<unknown>>> =
    new Map();

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (req.method !== 'GET') return next.handle(req);

    if (this.pendingRequests.has(req.url))
      return this.pendingRequests.get(req.url).asObservable();

    const subject = new Subject<HttpEvent<unknown>>();

    this.pendingRequests.set(req.url, subject);

    return next.handle(req).pipe(
      filter(res => res instanceof HttpResponse),
      tap(res => subject.next(res)),
      catchError(error => {
        subject.error(error);

        return throwError(() => error);
      }),
      finalize(() => {
        subject.complete();
        this.pendingRequests.delete(req.url);
      })
    );
  }
}
