import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { ProgressBarService } from '../services/progress-bar.service';

export const progressBar = new HttpContextToken<boolean>(() => false);

@Injectable()
export class ProgressBarInterceptor implements HttpInterceptor {
  constructor(private readonly progressBarService: ProgressBarService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.context.get(progressBar)) return next.handle(req);

    this.progressBarService.showProgressBar(true);

    return next
      .handle(req)
      .pipe(finalize(() => this.progressBarService.showProgressBar(false)));
  }
}
