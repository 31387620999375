<div class="search-breadcrumb">
  <div class="breadcrumb-item" *ngFor="let item of breadcrumbs; let i = index">
    <span class="pi pi-angle-right" *ngIf="i"></span>
    <span
      class="clickable-item"
      [appLinkActionHandler]="item.url"
      *ngIf="item.url; else label"
    >
      {{ item.label }}
    </span>
    <ng-template #label>{{ item.label }}</ng-template>
  </div>
</div>
