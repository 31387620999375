export enum AppRootRoute {
  SIGN_IN = 'sign-in',
  HOME = 'home',
  PROCESSES = 'processes',
  NEWS = 'news',
  WIKIS = 'wikis',
  FILES = 'files',
  CONTENT_CATEGORIES = 'content-categories',
  LOGOUT = 'logout',
  PROFILE = 'profile',
  SEARCH = 'search',
  DOCUMENT = 'document',
  ERRORS = 'errors',
  FORBIDDEN = 'forbidden',
  UNAUTHORIZED_TICKET = 'unauthorized-ticket',
  TICKETS = 'tickets',
  NOTIFICATIONS = 'notifications',
  ADMIN_CONTENT_CATEGORIES = 'admin/content-categories',
  ADMIN_WIKIS = 'admin/wikis',
  ADMIN_LANGUAGES = 'admin/languages',
  ADMIN_PROCESSES = 'admin/processes',
  ADMIN_FILES = 'admin/files',
  ADMIN_QUICKLINKS = 'admin/quicklinks',
  ADMIN_NEWS = 'admin/news',
  ADMIN_TICKETS = 'admin/tickets',
  ADMIN_SEARCH_KEYWORDS = 'admin/keywords',
}

export enum AppActionRoute {
  DELETE = 'delete',
  CREATE = 'create',
  EDIT = 'edit',
}
