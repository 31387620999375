import { Injectable } from '@angular/core';
import { BackwardConfirmationComponent } from '@avenir-client-web/backward-confirmation';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root',
})
export class LeaveDialogService {
  dialogRef: DynamicDialogRef;

  constructor(private readonly dialogService: DialogService) {}

  showLeaveConfirmationDialog(): DynamicDialogRef {
    const isDialogOpening: boolean =
      this.dialogService.dialogComponentRefMap.get(this.dialogRef)?.instance
        .visible;

    if (!isDialogOpening) {
      this.dialogRef = this.dialogService.open(BackwardConfirmationComponent, {
        closeOnEscape: false,
        showHeader: false,
        styleClass: 'confirmation-dialog',
      });
    }

    return this.dialogRef;
  }
}
