import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@avenir-client-web/button';
import { ButtonModule as PButton } from 'primeng/button';
import { DialogContentLayoutComponent } from './dialog-content-layout.component';

@NgModule({
  declarations: [DialogContentLayoutComponent],
  imports: [CommonModule, PButton, ButtonModule],
  exports: [DialogContentLayoutComponent],
})
export class DialogContentLayoutModule {}
