import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { MaxLengthModule } from '../../../../directives/max-length/src/lib/max-length.module';
import { InputComponent } from './input.component';

@NgModule({
  declarations: [InputComponent],
  imports: [CommonModule, InputTextModule, MaxLengthModule, FormsModule],
  exports: [InputComponent],
})
export class InputModule {}
