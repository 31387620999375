export enum TicketPriority {
  LOW = 1,
  MEDIUM,
  HIGH,
}

export enum TicketStatus {
  NEW = 1,
  ACTIVE,
  RESOLVED,
}

export enum TicketChatFormKey {
  CONTENT = 'content',
  PLAIN_CONTENT = 'plainContent',
  SENDER = 'sender',
}

export enum TicketFormKey {
  TYPE = 'type',
  PRIORITY = 'priority',
  MANAGED_BY = 'managedBy',
  ASSIGNEE = 'assignee',
  TITLE = 'title',
  CONTENT = 'content',
  PLAIN_CONTENT = 'plainContent',
  ROLES = 'roles',
  RECIPIENTS = 'recipients',
  CONTENT_CATEGORY = 'contentCategory',
  CONTENT_TYPE = 'contentType',
  RELATED_CONTENT = 'relatedContent',
  LINK = 'link',
}

export enum TicketAssignmentFormKey {
  ID = 'id',
  STATUS = 'status',
  PRIORITY = 'priority',
  ASSIGNEE = 'assignee',
  CONTENT_CATEGORY = 'contentCategory',
  CONTENT_TYPE = 'contentType',
  RELATED_CONTENT = 'relatedContent',
}

export enum TicketAssignmentFormAction {
  SAVE_BUTTON = 'saveButton',
}

export enum FormItemState {
  ENABLED,
  DISABLED,
  HIDDEN,
}

export enum TicketType {
  ACTION,
  INFO,
}
