import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EditorModule as PrimeEditorModule } from 'primeng/editor';
import { EditorComponent } from './editor.component';

@NgModule({
  imports: [CommonModule, PrimeEditorModule, FormsModule],
  declarations: [EditorComponent],
  exports: [EditorComponent],
})
export class EditorModule {}
