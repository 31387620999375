import { ElementRef, Injectable, NgZone } from '@angular/core';
import { filter, map, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResizeObserverService {
  private readonly resized$ = new Subject<ResizeObserverEntry[]>();

  private readonly resizeObserver: ResizeObserver;

  constructor(private readonly zone: NgZone) {
    this.resizeObserver = new ResizeObserver(entries => {
      this.zone.run(() => {
        this.resized$.next(entries);
      });
    });
  }

  notifyWhenResize(
    elementToResizeObserve: ElementRef
  ): Observable<ResizeObserverEntry> {
    return this.resized$.pipe(
      map(elements =>
        elements.find(
          element => element.target === elementToResizeObserve.nativeElement
        )
      ),
      filter(element => !!element)
    );
  }

  startObserving(el: ElementRef): void {
    this.resizeObserver.observe(el.nativeElement);
  }

  stopObserving(el: ElementRef): void {
    this.resizeObserver.unobserve(el.nativeElement);
  }
}
