export const languageApi = 'languages';

export const profileApi = 'profile';

export const escoSkillApi = 'search/ext-skill';

export const menuApi = 'menus';

export const contentCategoryApi = 'content-categories';

export const detailContentCategoryApi = `${contentCategoryApi}/detail`;

export const relatedContentsCategoryApi = 'related-contents';

export const dashboardContentCategoryApi = `dashboard/${contentCategoryApi}`;

export const contentCategoryMetadataApi = 'content-categories/metadata';

export const userApi = 'users';

export const tenantRoleApi = 'user-roles';

export const newsApi = 'news';

export const latestNewsApi = `${newsApi}/latest`;

export const setCurrentRoleApi = `${profileApi}/update/set-current-role`;

export const processApi = 'processes';

export const processMetadataApi = `${processApi}/metadata`;

export const quicklinkApi = 'quicklinks';

export const detailsQuicklinkApi = `${quicklinkApi}/detail`;

export const wikiIndexApi = 'wiki-indexes';

export const publishedWikiIndexApi = 'wiki-indexes/published';

export const detailWikiIndexApi = `${wikiIndexApi}/detail`;

export const wikiDetailApi = 'wiki-details';

export const searchProcessApi = 'search/process';

export const searchNewsApi = 'search/news';

export const searchWikiApi = 'search/wiki';

export const searchPeopleApi = 'search/user';

export const searchAzUsersApi = 'search/ext-user';

export const searchLearningSessionsApi = 'search/ext-learning';

export const searchCountApi = 'search/count';

export const dashboardQuicklinksApi = `dashboard/${quicklinkApi}`;

export const dashboardNewsApi = `dashboard/${newsApi}`;

export const workingHoursChartApi = `dashboard/working-hours`;

export const holidaysChartApi = `dashboard/holidays`;

export const relatedPeopleApi = `related-people`;

export const myTeamChartApi = `dashboard/my-team-data`;

export const ticketMetadataApi = `${contentCategoryApi}/published`;

export const ticketApi = 'tickets';

export const chatApi = `chats`;

export const getTicketManagersApi = `${userApi}/managed-ticket?categoryId=:categoryId&type=:type&relatedContentId=:relatedContentId`;

export const notificationApi = 'notifications';

export const metadataApi = 'metadata';

export const searchKeywordApi = 'search-keywords';
