<app-dialog-content-layout
  (confirmationCloseEvent)="close()"
  (closeEvent)="close()"
  (saveEvent)="close(true)"
  [saveLabel]="saveLabel"
>
  <div class="confirmation-wrapper">
    <span class="title" i18n>default.confirmation.title</span>
    <span class="message" i18n>backwardConfirmation.message</span>
  </div>
</app-dialog-content-layout>
