<div class="upload-image">
  <app-button
    (click)="showUploadImageDialog()"
    [label]="label"
    styleClass="upload-image-btn"
    piIcon="pi pi-image"
  >
  </app-button>

  <div *ngIf="value" class="preview-image">
    <div class="app-image">
      <app-image
        [imageId]="value.id"
        [compressionType]="compressionType"
        [isSvg]="isSvg"
      ></app-image>
    </div>
    <div class="name">
      <span>{{ value.name }}</span>
    </div>
    <a (click)="removeImage()" class="remove-btn" type="button">
      <span class="pi pi pi-times"></span>
    </a>
  </div>
</div>
