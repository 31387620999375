import { Type } from '@angular/core';

export interface ComponentLoaderConfig {
  componentType: Type<unknown>;
  params?: unknown;
  outputEvents?: unknown;
  /**
   * For bind this keyword in executor of outputEvents
   */
  hostBinding?: unknown;
}
