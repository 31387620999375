import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FilterOption, SearchCounts } from '../interfaces/search.interfaces';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchFilterComponent<T = unknown> implements OnChanges {
  @Input() filterTitle: string;

  @Input() options: FilterOption<T>[] = [];

  @Input() filter: unknown;

  @Input() searchCounts: SearchCounts;

  @Output() filterChange = new EventEmitter<unknown>();

  filterIdx = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter) {
      this.filterIdx = this.options.findIndex(x => x.value === this.filter);
    }
  }

  valueChange(idx: number): void {
    this.filterChange.emit(this.options[idx].value);
  }
}
