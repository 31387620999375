import { HubConnection as SignalRHubConnection } from '@microsoft/signalr';
import { HubEndpoint } from '../enums/signalr.enums';

export interface Notification<T> {
  data: T;
}

export interface HubConnection {
  [key: number]: {
    eventKeys: string[];
    connectionUrl: string;
    hub: SignalRHubConnection;
  };
}

export interface HubInfo {
  [key: number]: {
    eventKeys: string[];
    endpoint: HubEndpoint;
  };
}

export interface HubQueryParams {
  [key: string]: string | number;
}
