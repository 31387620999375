import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from '@avenir-client-web/dropdown';
import { ContentFilterComponent } from './content-filter.component';

@NgModule({
  declarations: [ContentFilterComponent],
  imports: [CommonModule, DropdownModule, ReactiveFormsModule],
  exports: [ContentFilterComponent],
})
export class ContentFilterModule {}
