import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HTTP_CACHE_CONFIG } from '../constants/http-cache.constants';
import {
  HttpCacheConfig,
  HttpCacheResponse,
} from '../models/http-cache.models';
import { CacheTimer } from '../utils/cache-timer';

@Injectable({
  providedIn: 'root',
})
export class HttpCacheService {
  private readonly cacheItems: Map<string, HttpCacheResponse> = new Map();

  constructor(
    @Inject(HTTP_CACHE_CONFIG) private readonly cacheConfig: HttpCacheConfig
  ) {}

  cache(url: string, response: HttpResponse<unknown>): void {
    Object.assign(response, {
      timer: new CacheTimer(this.cacheConfig.expiredIn),
    });

    this.cacheItems.set(url, response as HttpCacheResponse);
  }

  clear(): void {
    this.cacheItems.clear();
  }

  hasCache(url: string): boolean {
    return this.cacheItems.has(url) && !this.getCache(url).timer.isExpired;
  }

  getCache(url: string): HttpCacheResponse {
    return this.cacheItems.get(url);
  }
}
