interface SvgIconSet {
  iconRoute: string;
  iconNames: string[];
}
export const SVG_ICON_SETS: SvgIconSet[] = [
  {
    iconRoute: 'assets/images/icons',
    iconNames: [
      'mail',
      'change-role',
      'drag-drop',
      'download',
      'external-link',
      'arrow-right',
      'arrow-left',
      'search',
      'refresh',
      'bell',
      'mail-on',
      'mail-off',
      'paper-plane',
      'check',
      'times-circle',
      'forbidden',
    ],
  },
];
