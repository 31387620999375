<div class="dialog-layout-wrapper">
  <button
    pButton
    pRipple
    type="button"
    icon="pi pi-times"
    class="close-btn p-button-rounded p-button-text"
    (click)="closeEvent.emit()"
  ></button>
  <ng-content></ng-content>
  <ng-container
    *ngIf="actionButtonsTemplate; else defaultActionButtons"
    [ngTemplateOutlet]="actionButtonsTemplate"
  ></ng-container>
</div>

<ng-template #defaultActionButtons>
  <div class="action">
    <app-button
      [label]="cancelLabel"
      (click)="closeEvent.emit()"
      styleClass="bg-gray"
    ></app-button>
    <app-button [label]="saveLabel" (click)="saveEvent.emit()"></app-button>
  </div>
</ng-template>
