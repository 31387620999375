<div class="field radio-button-container">
  <div class="flex align-items-center justify-content-between mb-2">
    <label *ngIf="label" class="block" [ngClass]="{ required: hasAsterisk }"
      >{{ label }}
    </label>
  </div>

  <div class="radio-button-group">
    <div class="field-radiobutton" *ngFor="let option of options">
      <p-radioButton
        [name]="name"
        [inputId]="option.label"
        [value]="option.value"
        [(ngModel)]="value"
        [disabled]="disabled"
        (onClick)="onValueChange(option.value)"
      ></p-radioButton>
      <label [for]="option.label">{{ option.label }}</label>
    </div>
  </div>
  <ng-content></ng-content>
</div>
