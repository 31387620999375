import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from '../../../base-control';
import { RadioButtonOption } from './radio-button.model';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class RadioButtonComponent extends BaseControlComponent {
  @Input() name = '';

  @Input() options: RadioButtonOption[] = [];
}
