<div class="forbidden-page-layout">
  <div class="header">
    <div class="background">
      <div class="preview-image">
        <img
          [src]="forbiddenPageInfo.previewImage || defaultPreviewImage"
          class="no-border-radius"
          alt=""
        />
      </div>
    </div>

    <div class="title-box">
      <span class="title">{{ forbiddenPageInfo.title }}</span>
      <div class="avatar">
        <svg-icon
          [name]="forbiddenPageInfo.groupImage || defaultGroupImage"
          class="stroked"
        ></svg-icon>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="description-title">
      <span> {{ forbiddenPageInfo.descriptionTitle }}</span>
    </div>
    <div class="description">
      <div
        class="text-content"
        [innerHTML]="
          forbiddenPageInfo.description
            | textReplace: { '[newLine]': '<br/>' }
            | safe
        "
      ></div>
    </div>

    <div class="navigation-section">
      <app-navigation-btn
        [btn]="btn"
        *ngFor="let btn of forbiddenPageInfo.redirectionBtns"
      ></app-navigation-btn>
    </div>
  </div>
</div>
