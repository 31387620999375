import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface BroadcastEvent<T> {
  key: string;
  data?: T;
}

@Injectable({ providedIn: 'root' })
export class BroadcasterService {
  private readonly _eventBus = new Subject<BroadcastEvent<unknown>>();

  broadcast(key: string, data?: unknown): void {
    this._eventBus.next({ key, data });
  }

  on<T>(key: unknown): Observable<T> {
    return this._eventBus.asObservable().pipe(
      filter(event => event.key === key),
      map(event => event.data as T)
    );
  }
}
