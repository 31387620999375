import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressSpinnerModule as PrimengProgressSpinnerModule } from 'primeng/progressspinner';
import { ProgressSpinnerComponent } from './progress-spinner.component';
@NgModule({
  imports: [CommonModule, PrimengProgressSpinnerModule],
  declarations: [ProgressSpinnerComponent],
  exports: [ProgressSpinnerComponent],
})
export class ProgressSpinnerModule {}
