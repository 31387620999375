import { Component, Inject } from '@angular/core';
import { APP_CONFIG, BaseAppConfig } from '@avenir-client-web/models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  copyright: string = this.config.copyright;

  constructor(@Inject(APP_CONFIG) private readonly config: BaseAppConfig) {}
}
