import { MenuItem } from 'primeng/api';
import { FilterOptionValue } from '../enums/search.enums';

export interface FilterOption<T> {
  label: string;
  value: T;
  visible?: boolean;
  contentTypes: number[];
}

export interface SearchDataModel {
  id: number | string;
  title: string;
  heading: string;
  description: string;
  type?: FilterOptionValue;
}

export interface SearchResult extends SearchDataModel {
  url: string;
  processStepId?: number;
  contentCategory?: ContentCategory;
  breadcrumb?: MenuItem;
  keywords?: string[];
  alternatives?: AlternativeResult[];
}

export interface AlternativeResult {
  title: string;
  description: string;
  processStepId: number;
}

export interface SearchResponse extends SearchDataModel {
  externalLink?: string;
  contentCategory?: ContentCategory;
}

export interface SearchQueryOption {
  keyword: string;
  filter: unknown;
}

export type SearchCounts = {
  [key in FilterOptionValue]: number;
};

interface ContentCategory {
  id: number;
  name?: string;
}

export class ShownSearchResultButton {
  label: string;

  show: () => void;

  styleClass: string;

  icon: string;

  iconPos: string;

  visible?: boolean;
}
