import { ContentCategory } from '@app/content-category/models/content-category.models';
import { ProfileDetail } from '@app/profile/models/profile.models';
import { TenantLanguage } from '@app/tenant-language/models/tenant-language.model';
import { WikiLinkType } from '@app/wiki-page/enums/wiki-link-type.enum';
import { Status } from '@avenir-client-web/enums';
import { FileItem } from '@avenir-client-web/file';
import { BaseWikiDetail } from '@avenir-client-web/wiki';
import { MetadataItem } from '@core/models/metadata-item.model';
import { TenantRole } from '@core/models/role.model';

export enum WikiFormKey {
  ID = 'id',
  CONTENT_CATEGORY = 'contentCategory',
  STATUS = 'status',
  CREATED_DATE = 'createdDate',
  MODIFIED_DATE = 'modifiedDate',
  MODIFIED_BY = 'modifiedBy',
  RELEVANT_ROLES = 'relevantRoles',
  INFOS = 'infos',
  TITLE = 'title',
  DESCRIPTION = 'description',
  LINKS = 'links',
  LANGUAGE = 'language',
  PREVIEW_IMAGE = 'previewImage',
  GROUP_IMAGE = 'groupImage',
  SIDEBAR = 'sidebar',
  SECTIONS = 'sections',
  CONTENT = 'content',
  PLAIN_CONTENT = 'plainContent',
  PARENT_ID = 'parentId',
  ORDER = 'order',
  RESPONSIBLE_PERSONS = 'responsiblePersons',
}

export interface MultilingualDetailWikiIndex extends WikiBase {
  infos: WikiIndexInfo[];
}

export interface MultilingualDetailWikiDetail extends WikiBase {
  parentId: number;
  infos: WikiDetailInfo[];
  responsiblePersons: ProfileDetail[];
}

export interface WikiIndexMetadata {
  relevantRoles: TenantRole[];
  languages: TenantLanguage[];
  contentCategories: ContentCategory[];
}

export interface WikiDetailMetadata extends WikiIndexMetadata {
  wikiParents: WikiBase[];
}

export interface WikiLink {
  id: number;
  order?: number;
  linkText: string;
  description?: string;
  link: string;
  isExternalLink: boolean;
  linkType: WikiLinkType;
}

export enum WikiType {
  INDEX = 'index',
  DETAIL = 'detail',
}

export interface WikiIndexResponse {
  id: number;
  title: string;
  imageId: string;
  links: WikiLink[];
}

export interface WikiDetailResponse extends BaseWikiDetail {
  id: number;
  previewImageId: string;
  groupImageId: string;
}

export interface WikiDetailSection {
  id: number;
  order: number;
  content: string;
  plainContent: string;
  links: WikiLink[];
}

interface WikiBase {
  id: number;
  contentCategory: ContentCategory;
  status: Status;
  modifiedBy: string;
  createdDate: string;
  modifiedDate: string;
  relevantRoles: TenantRole[];
}

export interface WikiIndexInfo extends WikiInfoBase {
  links: WikiLink[];
  groupImage: FileItem;
}

export interface WikiDetailInfo extends WikiInfoBase {
  sidebar: string;
  sections: WikiDetailSection[];
}

interface WikiInfoBase {
  id: number;
  title: string;
  description: string;
  previewImage: FileItem;
  language: MetadataItem;
}
