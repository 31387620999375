import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthMsalService } from '@app/auth-msal/services/auth-msal.service';
import { AppRootRoute } from '@core/enums/app-route.enum';
import { ProfileService } from '@core/services/profile.service';
import { UserService } from '@shared/services/user.service';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-signing-in',
  templateUrl: './signing-in.component.html',
  styleUrls: ['./signing-in.component.scss'],
})
export class SigningInComponent implements OnInit, OnDestroy {
  private readonly compUnsubscribe$ = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly profileService: ProfileService,
    private readonly authMsalService: AuthMsalService,
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService
      .onSignIn()
      .pipe(
        tap(() => {
          this.profileService.setUserActiveState(true);
          this.authMsalService.initMsal();
          this.router.navigate([AppRootRoute.HOME]);
        }),
        takeUntil(this.compUnsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.compUnsubscribe$.next();
    this.compUnsubscribe$.complete();
  }
}
