import { EventKeys, HubEndpoint, HubType } from '../enums/signalr.enums';
import { HubInfo } from '../models/signalr.models';

export const hubInfo: HubInfo = {
  [HubType.GLOBAL]: {
    endpoint: HubEndpoint.GLOBAL,
    eventKeys: [
      EventKeys.GLOBAL.BELL_INDICATOR,
      EventKeys.GLOBAL.NEW_NOTIFICATION,
    ],
  },
  [HubType.TICKET]: {
    endpoint: HubEndpoint.TICKET,
    eventKeys: [EventKeys.TICKET.NEW_CHAT_MESSAGE],
  },
};
