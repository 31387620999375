import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BroadcasterService } from '@avenir-client-web/broadcaster';
import { EventKeys } from '@avenir-client-web/signalr';
import { ToastService, ToastType } from '@avenir-client-web/toast';
import { AppRootRoute } from '@core/enums/app-route.enum';
import { MessageService } from 'primeng/api';
import { filter, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalNotificationService {
  isCurrentPage$ = new Subject<void>();

  constructor(
    private readonly broadcaster: BroadcasterService,
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly messageService: MessageService
  ) {
    this.toastService.click$
      .asObservable()
      .pipe(filter(this.isCurrentPage.bind(this)))
      .subscribe(() => this.isCurrentPage$.next());
  }

  showNotificationToast(): void {
    this.messageService.add({
      severity: ToastType.INFO,
      summary: $localize`toast.newNotification`,
      data: {
        route: [AppRootRoute.NOTIFICATIONS],
      },
    });
  }

  handleNotificationToast(): void {
    this.broadcaster
      .on(EventKeys.GLOBAL.NEW_NOTIFICATION)
      .subscribe(this.showNotificationToast.bind(this));
  }

  private isCurrentPage(route: string | string[]): boolean {
    const url = Array.isArray(route) ? route.join('/') : route;

    return this.router.url.includes(url);
  }
}
