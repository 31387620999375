<ngx-extended-pdf-viewer
  [src]="url"
  [showOpenFileButton]="false"
  [showPresentationModeButton]="true"
  [theme]="theme"
  [zoom]="zoom"
  [filenameForDownload]="fileName"
  [showStampEditor]="false"
>
</ngx-extended-pdf-viewer>
