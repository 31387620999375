import { Inject, Injectable } from '@angular/core';
import { ProfileDetail } from '@app/profile/models/profile.models';
import { SearchCountsResponse } from '@app/search/models/search.models';
import { WikiType } from '@app/wiki-page/models/wiki.models';
import { APP_CONFIG, BaseAppConfig } from '@avenir-client-web/models';
import {
  FilterOptionValue,
  SearchCounts,
  SearchResponse,
  SearchResult,
  SearchWikiResponse,
} from '@avenir-client-web/search';
import { AppRootRoute } from '@core/enums/app-route.enum';
import { MenuItem } from 'primeng/api';
@Injectable({
  providedIn: 'root',
})
export class SearchMapperService {
  constructor(@Inject(APP_CONFIG) readonly config: BaseAppConfig) {}

  mapSearchResultResponse(
    searchResponse: SearchResponse,
    filterOption: FilterOptionValue
  ): SearchResult {
    let url = location.origin;
    let heading = searchResponse.heading;

    let breadcrumb: MenuItem;
    let type: FilterOptionValue;

    switch (filterOption) {
      case FilterOptionValue.PROCESS:
        url += `/${AppRootRoute.CONTENT_CATEGORIES}/${searchResponse.contentCategory?.id}/${AppRootRoute.PROCESSES}/${searchResponse.id}`;
        type = FilterOptionValue.PROCESS;
        breadcrumb = {
          url: `${location.origin}/${AppRootRoute.CONTENT_CATEGORIES}`,
          label: $localize`search.filter.process`,
        };
        break;
      case FilterOptionValue.NEWS:
        url += `/${AppRootRoute.NEWS}/${searchResponse.id}`;
        type = FilterOptionValue.NEWS;

        breadcrumb = {
          url: `${location.origin}/${AppRootRoute.NEWS}`,
          label: $localize`search.filter.news`,
        };
        break;
      case FilterOptionValue.WIKI: {
        const result = searchResponse as SearchWikiResponse;

        type = FilterOptionValue.WIKI;

        if (result.url?.length > 0) {
          url = result.url;
          heading = result.linkText;
        } else {
          url += `/${AppRootRoute.WIKIS}/${WikiType.DETAIL}/${result.id}`;
        }

        breadcrumb = {
          url: `${location.origin}/${AppRootRoute.WIKIS}`,
          label: $localize`search.filter.wiki`,
        };
        break;
      }
      case FilterOptionValue.ELEARNING:
        url = searchResponse.externalLink;
        heading = searchResponse.title;
        type = FilterOptionValue.ELEARNING;

        breadcrumb = {
          url: this.config.appSettings.learning.url,
          label: $localize`search.filter.elearning`,
        };
        break;
    }

    return {
      ...searchResponse,
      url,
      type,
      heading,
      breadcrumb,
    };
  }

  mapProfileDetail(profileDetail: ProfileDetail): SearchResult {
    return {
      id: profileDetail.aadId,
      title: profileDetail.name,
      heading: profileDetail.name,
      type: FilterOptionValue.PEOPLE,
      url: `${location.origin}/${AppRootRoute.PROFILE}/${profileDetail.aadId}`,
      description: this.generateUserDescription(profileDetail),
      breadcrumb: {
        label: $localize`search.filter.people`,
      },
    };
  }

  mapSearchCounts(searchCounts: SearchCountsResponse): SearchCounts {
    const total = Object.values(searchCounts).reduce((a, b) => a + b, 0);

    return {
      [FilterOptionValue.ALL]: total,
      [FilterOptionValue.NEWS]: searchCounts.news,
      [FilterOptionValue.PROCESS]: searchCounts.process,
      [FilterOptionValue.WIKI]: searchCounts.wikiChild,
      [FilterOptionValue.PEOPLE]: searchCounts.people,
      [FilterOptionValue.ELEARNING]: searchCounts.learning,
    };
  }

  private generateUserDescription({
    jobTitle,
    department,
    businessPhones,
    officeLocation,
    email,
    mobilePhone,
  }: ProfileDetail): string {
    return [
      jobTitle,
      businessPhones?.[0],
      mobilePhone,
      email,
      department,
      officeLocation,
    ]
      .filter(Boolean)
      .join(' | ');
  }
}
