import { Component, Input, ViewEncapsulation } from '@angular/core';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PdfViewerComponent {
  @Input() url: string;

  @Input() fileName = 'document.pdf';

  @Input() zoom = 'auto';

  @Input() theme = 'dark';

  constructor() {
    pdfDefaultOptions.assetsFolder = 'assets/pdf-viewer';
  }
}
