<div class="search-filter-tabs">
  <h3 class="search-filter-tabs__header">{{ filterTitle }}</h3>

  <p-tabView
    styleClass="search-filter-tabs__tabview"
    (onChange)="valueChange($event?.index)"
    [(activeIndex)]="filterIdx"
  >
    <p-tabPanel *ngFor="let option of options">
      <ng-template pTemplate="header">
        <span class="search-counter" *ngIf="searchCounts">{{
          searchCounts[option.value]
        }}</span
        ><span>{{ option.label }}</span></ng-template
      >
    </p-tabPanel>
  </p-tabView>
</div>

<app-dropdown
  styleClass="search-filter-dropdown primary"
  [options]="options"
  (valueChange)="valueChange($event)"
  scrollHeight="100%"
  [value]="filter"
  [itemTemplate]="itemTemplate"
  [selectedItemTemplate]="itemTemplate"
  appendTo="body"
>
</app-dropdown>

<ng-template let-item #itemTemplate>
  <span>
    {{ item.label }}
    <span *ngIf="searchCounts">({{ searchCounts[item.value] }})</span>
  </span>
</ng-template>
