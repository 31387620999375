import { enableProdMode } from '@angular/core';
import '@angular/localize/init';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
  clearTranslations,
  loadTranslations,
} from '@avenir-client-web/localizer';
import { APP_CONFIG, BaseAppConfig, LogLevel } from '@avenir-client-web/models';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const NOT_FOUND_HTML_PAGE = 'assets/config/not-found.html';

async function getConfigData(): Promise<BaseAppConfig> {
  try {
    const configUrl = `/${environment.configUrl}`;
    const configResponse = await fetch(configUrl);
    const configJson = await configResponse.json();
    const logLevel = LogLevel[configJson.logLevel];
    const configToUse = {
      ...configJson,
      logLevel,
    };

    if (environment.production) {
      enableProdMode();
    } else {
      console.log('Running with config', configToUse);
    }

    return configToUse;
  } catch (error) {
    window.location.assign(NOT_FOUND_HTML_PAGE);
    throw error;
  }
}

async function getTranslationData(configToUse: BaseAppConfig): Promise<void> {
  let locale: string = window.location.pathname.split('/')[1];
  const isLocaleSupported = configToUse.supportedLanguages.some(
    (l: string) => l === locale
  );

  if (!isLocaleSupported) locale = configToUse.defaultLanguage;

  const translationUrl = `assets/i18n/messages.${locale}.json`;
  const response = await fetch(translationUrl);
  const parsedMessages = await response.json();

  if (parsedMessages.locale.toLowerCase() !== locale.toLowerCase()) {
    console.warn(
      'The locale of the translation file is different from the current locale of the app.'
    );

    return;
  }

  clearTranslations();
  loadTranslations(parsedMessages.locale, parsedMessages.translations);
}

async function initializeApp(): Promise<void> {
  const configToUse = await getConfigData();

  await getTranslationData(configToUse);

  platformBrowserDynamic([{ provide: APP_CONFIG, useValue: configToUse }])
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
}

initializeApp();
