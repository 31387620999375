<div class="field">
  <div class="flex align-items-center justify-content-between">
    <label *ngIf="label" class="block" [ngClass]="{ required: hasAsterisk }"
      >{{ label }}
    </label>

    <div class="field-length" *ngIf="maxLength">
      {{ value?.length ?? 0 }}/{{ maxLength }}
    </div>
  </div>
  <div class="textarea-container">
    <textarea
      pInputTextarea
      [disabled]="disabled"
      [autoResize]="autoResize"
      [rows]="rows"
      [placeholder]="placeholder ?? ''"
      class="form-control"
      (input)="onValueChange($event.target.value)"
      (blur)="onBlur()"
      [(ngModel)]="value"
      [appMaxLength]="maxLength"
    ></textarea>
  </div>
  <ng-content></ng-content>
</div>
