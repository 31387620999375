import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnChanges {
  @Input() size = 'normal';

  @Input() shape = 'square';

  @Input() avatarUrl: SafeUrl;

  hasImage = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.avatarUrl.currentValue) {
      this.hasImage = true;
    }
  }

  fallbackImage(): void {
    this.hasImage = false;
  }

  containerClass(): { [key: string]: boolean } {
    return {
      'avatar-image': this.hasImage,
      'avatar-circle': this.shape === 'circle',
      'avatar-lg': this.size === 'large',
      'avatar-xl': this.size === 'xlarge',
    };
  }
}
