enum GlobalKeys {
  BELL_INDICATOR = 'BellIndicator',
  NEW_NOTIFICATION = 'NewNotification',
}

enum TicketKeys {
  NEW_CHAT_MESSAGE = 'NewChatMessage',
}

export enum HubType {
  GLOBAL,
  TICKET,
}

export enum HubEndpoint {
  GLOBAL = 'global-hub',
  TICKET = 'ticket-hub',
}

export class EventKeys {
  static readonly GLOBAL = GlobalKeys;

  static readonly TICKET = TicketKeys;
}
