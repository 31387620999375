import { Component, ViewEncapsulation } from '@angular/core';
import { Message } from 'primeng/api';
import { Toast } from 'primeng/toast';
import { ToastService } from './toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToastComponent {
  constructor(readonly toastService: ToastService) {}

  onClose(
    { target }: { target: HTMLButtonElement },
    toast: Toast,
    message: Message
  ): void {
    const index = +target
      .closest('p-toastitem')
      .getAttribute('ng-reflect-index');

    toast.onMessageClose({
      index,
      message,
    });
  }
}
